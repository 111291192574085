import { PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';

import useLocalStorage from '../../dashboard/components/hooks/useLocalStorage';
import { ConsoleContext } from './App';

const MIN_HEIGHT = 300;
const DEFAULT_HEIGHT = 500;
const INLINE_MAX_HEIGHT = 700;
const EMBED_MAX_HEIGHT = '90vh';

const StyledConsoleContainer = styled.div<{ context: ConsoleContext }>(({ theme, context }) => {
  const shared = css`
    background: ${theme.colors.surface.base.background};
    display: flex;
    flex-direction: column;
  `;

  const context_styles: Record<ConsoleContext, any> = {
    embed: css`
      ${shared}
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: ${EMBED_MAX_HEIGHT};
      border-top: ${theme.border};
      box-shadow: ${theme.elevation[2]};
    `,
    inline: css`
      ${shared}
      border-radius: ${theme.radius.large};
      position: relative;
      min-height: ${MIN_HEIGHT}px;
      max-height: ${INLINE_MAX_HEIGHT}px;

      ${StyledScrollContainer} {
        border: ${theme.border};
        box-shadow: ${theme.elevation[0]};
        border-radius: ${theme.radius.large};
      }
    `,
    fullpage: css`
      ${shared}
      height: 100vh;
    `,
  };
  return context_styles[context];
});

const StyledScrollContainer = styled.div(
  ({ theme }) => css`
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    ::-webkit-scrollbar {
      display: none;
    }
  `,
);

const StyledEmbedResizeHandle = styled.div<{ context: ConsoleContext }>(
  ({ theme, context }) => css`
    position: absolute;

    ${context === 'inline' &&
    css`
      bottom: 0;
    `}
    ${context === 'embed' &&
    css`
      top: 0;
    `}

    left: 0;
    right: 0;
    height: 6px;
    cursor: row-resize;
    z-index: ${theme.zindex.fixed + 1};

    ${context === 'embed' &&
    css`
      &:hover {
        &:before {
          display: block;
          position: absolute;
          content: '';
          border-top: 3px solid ${theme.colors.outline.neutral};
          width: 100%;
          height: 1px;
          left: 0;
          right: 0;
          top: -2px;
        }
      }
    `}

    &:after {
      position: absolute;
      content: '';
      width: 32px;
      height: 3px;
      border-radius: 8px;
      top: ${context === 'inline' ? '0' : '3px'};
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      background-color: ${theme.colors.outline.neutral};
    }
  `,
);

let drag_start_position = 0;
const Layout: React.FC<PropsWithChildren<{ context: ConsoleContext }>> = ({
  context,
  children,
}) => {
  const [persisted_height, setPersistedHeight] = useLocalStorage(
    `pref:console_height:${context}`,
    DEFAULT_HEIGHT,
  );
  const [height, setHeight] = useState(persisted_height);

  const onMouseMove = (e: MouseEvent) => {
    if (!drag_start_position) {
      drag_start_position = e.clientY;
    }
    e.preventDefault();
    let mouse_position_height = 0;
    if (context === 'inline') {
      const height_with_boundary = height > INLINE_MAX_HEIGHT ? INLINE_MAX_HEIGHT : height;
      mouse_position_height = height_with_boundary + (e.clientY - drag_start_position);
    } else if (context === 'embed') {
      mouse_position_height = window.innerHeight - e.clientY;
    }

    const console_height = mouse_position_height < MIN_HEIGHT ? MIN_HEIGHT : mouse_position_height;
    setHeight(console_height);
    setPersistedHeight(console_height);
  };
  const onDragStart = () => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onDragEnd);
  };

  const onDragEnd = () => {
    drag_start_position = 0;
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onDragEnd);
  };

  return (
    <>
      <StyledConsoleContainer
        context={context}
        style={context !== 'fullpage' ? { height: `${persisted_height}px` } : {}}>
        {context === 'embed' && (
          <StyledEmbedResizeHandle context={context} onMouseDown={onDragStart} />
        )}
        <StyledScrollContainer>{children}</StyledScrollContainer>
        {context === 'inline' && (
          <StyledEmbedResizeHandle context={context} onMouseDown={onDragStart} />
        )}
      </StyledConsoleContainer>
    </>
  );
};

export default Layout;
