export const colors = {
  white: '#FFFFFF',
  black: '#141412',
  surfaces: {
    '0': '#141412',
    '1': '#20201E',
    '2': '#272725',
    '3': '#2E2E2C',
    '4': '#30302E',
    '5': '#323230',
  },
  neutral: {
    '950': '#141414',
    '900': '#292929',
    '800': '#525252',
    '700': '#7A7A7A',
    '600': '#A3A3A3',
    '500': '#CCCCCC',
    '400': '#D6D6D6',
    '300': '#E0E0E0',
    '200': '#EBEBEB',
    '100': '#F5F5F5',
    '50': '#FAFAFA',
  },
  stone: {
    '950': '#141412',
    '900': '#292825',
    '800': '#52504A',
    '700': '#7A786E',
    '600': '#A3A093',
    '500': '#CCC8B8',
    '400': '#D6D3C6',
    '300': '#E0DED4',
    '200': '#EBE9E3',
    '100': '#F5F4F1',
    '50': '#FAFAF8',
  },
  blue: {
    '950': '#000714',
    '900': '#000E29',
    '800': '#001B52',
    '700': '#00297A',
    '600': '#0036A3',
    '500': '#0044CC',
    '400': '#3369D6',
    '300': '#668FE0',
    '200': '#99B4EB',
    '100': '#CCDAF5',
    '50': '#E5ECFA',
  },
  green: {
    '950': '#000D06',
    '900': '#001A0D',
    '800': '#00331A',
    '700': '#004D26',
    '600': '#006633',
    '500': '#008040',
    '400': '#339966',
    '300': '#66B38C',
    '200': '#99CCB3',
    '100': '#CCE6D9',
    '50': '#E5F2EC',
  },
  yellow: {
    '950': '#1A1400',
    '900': '#332900',
    '800': '#665200',
    '700': '#997A00',
    '600': '#CCA300',
    '500': '#FFCC00',
    '400': '#FFD633',
    '300': '#FFE066',
    '200': '#FFEB99',
    '100': '#FFF5CC',
    '50': '#FFFAE5',
  },
  red: {
    '950': '#140402',
    '900': '#290704',
    '800': '#520E08',
    '700': '#7A150C',
    '600': '#A31C10',
    '500': '#CC2314',
    '400': '#D64F43',
    '300': '#E07B72',
    '200': '#EBA7A1',
    '100': '#F5D3D0',
    '50': '#FAE9E7',
  },
  dark_levels_1: {
    '0': '#141412',
    '1': '#20201E',
    '2': '#272725',
    '3': '#2E2E2C',
    '4': '#30302E',
    '5': '#353533',
  },
  dark_levels: {
    '0': '#141412',
    '1': '#1B1B19',
    '2': '#222220',
    '3': '#292927',
    '4': '#30302E',
    '5': '#373736',
  },
};
