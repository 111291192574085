import { Form, Formik } from 'formik';
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import Modal from './Modal';
import { IconName } from './base/Icon';

type Context = {
  showDialog: (
    onConfirm: (values: object) => void,
    onDecline?: () => void,
    options?: {
      title?: string;
      message?: JSX.Element | string;
      danger?: boolean;
      submit_label?: string;
      cancel_label?: string;
      submit_icon?: IconName;
      is_loading?: boolean;
      form_props?: {
        initial_values?: object;
        validate?: (v: object) => object;
        Fields: (props: { values }) => JSX.Element;
      };
    },
  ) => void;
};

const DialogContext = createContext<Context>({ showDialog: () => ({}) });
const { Provider } = DialogContext;

export type ShowDialog = { is_open?: boolean; is_loading?: boolean } & ((
  onConfirm: any,
  onDecline: any,
  options: any,
) => void);

export const DialogProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [dialog, setDialog] = useState<
    | ({
        onConfirm: Parameters<Context['showDialog']>[0];
        onDecline?: Parameters<Context['showDialog']>[1];
      } & Parameters<Context['showDialog']>[2])
    | null
  >(null);

  const showDialog = useCallback((onConfirm, onDecline, options) => {
    setDialog({ onConfirm, onDecline, ...options });
  }, []) as ShowDialog;

  showDialog.is_open = !!dialog;
  showDialog.is_loading = !!dialog?.is_loading;

  const context = { showDialog };

  return (
    <Provider value={context}>
      {children}
      {dialog && (
        <Formik
          initialValues={dialog.form_props?.initial_values || {}}
          validateOnMount
          validate={dialog.form_props?.validate || undefined}
          onSubmit={(v) => {
            (async () => {
              await dialog.onConfirm(v);
            })().then(() => {
              setDialog(null);
            });
          }}>
          {(props) => (
            <Form>
              <Modal
                title={dialog.title || 'Are you sure?'}
                submit_label={dialog.submit_label || 'Yes'}
                submit_icon={dialog.submit_icon}
                cancel_label={dialog.cancel_label || 'No'}
                danger={dialog.danger ?? false}
                is_submitting={props.isSubmitting || dialog.is_loading}
                submit_disabled={props.isSubmitting || !props.isValid}
                onSubmit={props.handleSubmit}
                onClose={() => {
                  dialog.onDecline && dialog.onDecline();
                  setDialog(null);
                }}
                onCancel={() => {
                  dialog.onDecline && dialog.onDecline();
                  setDialog(null);
                }}>
                {dialog.message}
                {dialog.form_props?.Fields && <dialog.form_props.Fields values={props.values} />}
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('useDialog must be within a used with a DialogProvider');
  }

  return context.showDialog;
};
