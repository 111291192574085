import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import Button from '../../../dashboard/components/common/base/Button';
import Tabs from '../../../dashboard/components/common/base/Tabs';
import Tooltip from '../../../dashboard/components/common/base/Tooltip';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import useCopyASCurl from '../../../dashboard/components/hooks/useCopyAsCurl';
import isEmpty from '../../../dashboard/utils/isEmpty';
import { ConsoleResourceContext } from '../context/ConsoleResourceContext';
import { ConsoleSidebarContext } from '../context/ConsoleSidebarContext';
import DestinationForm from './DestinationForm';
import RequestTab from './RequestTab';
import ResponseTab from './ResponseTab';
import Text from '../../../dashboard/components/common/base/Text';

const StyledWrapper = styled(Div)(
  ({ theme }) => css`
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    flex-grow: 1;
    contain: paint;
    border-radius: ${theme.radius.large};
    padding-bottom: ${theme.spacing(20)};
  `,
);

const StyledSidebarHeader = styled(Div)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    background-color: ${theme.colors.surface.base.background};
    border-bottom: ${theme.border};
    z-index: 2;
  `,
);

const RequestSidebar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { sidebar_data, selected_entry } = useContext(ConsoleSidebarContext);
  const { webhook } = useContext(ConsoleResourceContext);
  const [active_tab, setActiveTab] = useState<'request' | 'response'>('request');
  const copyAsCurl = useCopyASCurl({
    type:
      sidebar_data && ['event', 'request'].includes(sidebar_data.type)
        ? (sidebar_data?.type as 'event' | 'request')
        : undefined,
    id: selected_entry?.id,
    event_data: sidebar_data?.entry?.data || undefined,
    destination: webhook?.destination,
  });

  return (
    <StyledWrapper>
      <StyledSidebarHeader
        flex={{ justify: 'space-between' }}
        style={{ position: 'sticky', top: 0 }}
        p={{ t: 2, x: 4 }}>
        <Tabs
          compact
          active_tab={active_tab}
          onTabSelected={(tab) => setActiveTab(tab as 'request' | 'response')}
          border={false}
          tabs={[
            { key: 'request', label: 'Request' },
            { key: 'response', label: 'Response' },
          ]}
        />
        <Div p={{ b: 2 }} flex={{ align: 'center' }}>
          <Tooltip tooltip="Copy request as cURL" placement="bottom-end">
            <Button small minimal icon="copy" m={{ r: 1 }} onClick={copyAsCurl}>
              cURL
            </Button>
          </Tooltip>
          <Button small minimal icon="close" onClick={onClose} />
        </Div>
      </StyledSidebarHeader>
      {active_tab === 'request' && <RequestTab setTab={setActiveTab} />}
      {active_tab === 'response' && <ResponseTab />}
    </StyledWrapper>
  );
};

const DestinationSidebar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { webhook } = useContext(ConsoleResourceContext);
  return (
    <StyledWrapper>
      <StyledSidebarHeader
        flex={{ justify: 'space-between', align: 'center' }}
        style={{ position: 'sticky', top: 0 }}
        p={{ y: 2.5, x: 4 }}>
        <Text heading>{!isEmpty(webhook) ? 'Destination Settings' : 'Add Destination'}</Text>
        <Button small minimal icon="close" onClick={onClose} />
      </StyledSidebarHeader>
      <DestinationForm submit_label={!isEmpty(webhook) ? 'Update' : 'Add'} />
    </StyledWrapper>
  );
};

const Sidebar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { sidebar_data } = useContext(ConsoleSidebarContext);

  if (sidebar_data?.type === 'destination') {
    return <DestinationSidebar onClose={onClose} />;
  }

  return <RequestSidebar onClose={onClose} />;
};

export default Sidebar;
