import { useRef } from 'react';
import styled, { useTheme } from 'styled-components';

import MonacoEditor from '@monaco-editor/react';

import editor_jsdocs from '../../utils/editor-jsdocs';
import useOnClickOutside from '../hooks/useOnClickOutside';
import Divider from './base/Divider';
import Text from './base/Text';
import { Div, StyledUtilsProps } from './helpers/StyledUtils';

interface Props extends StyledUtilsProps {
  value: string;
  error?: string;
  height?: string;
  padding?: number;
  scroll?: boolean;
  small_text?: boolean;
  language?: 'json' | 'javascript' | 'typescript' | 'text';
  display?: 'minimal' | 'full';
  prevent_theme_change?: boolean;
  jsdocs?: keyof typeof editor_jsdocs;
  format_on_blur?: boolean;
  disabled?: boolean;
  onChange?: (v: string) => void;
  cmdEnter?: () => void;
}

const options = {
  minimal: {
    lineDecorationsWidth: '0ch',
    renderLineHighlightOnlyWhenFocus: true,
    hideCursorInOverviewRuler: true,
    overviewRulerBorder: false,
    lineNumbers: 'off' as any,
  },
  full: {},
};

const StyledHeightWrapper = styled.div<{ height?: string }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 1px;
  ${({ height }) => height && `height: ${height};`}
  > section {
    flex-grow: 1;
    height: unset !important;
  }
  > div {
    position: sticky;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

const Editor: React.FC<Props> = ({
  scroll,
  value,
  error,
  height,
  language,
  display,
  prevent_theme_change,
  jsdocs,
  format_on_blur,
  disabled,
  small_text,
  onChange,
  cmdEnter,
}) => {
  language = language || 'json';
  const theme = useTheme();

  const editor_ref = useRef<any>(null);
  const wrapper_ref = useOnClickOutside(() => {
    if (editor_ref?.current) {
      format_on_blur && editor_ref.current!.getAction('editor.action.formatDocument')?.run();
    }
  });

  return (
    <>
      <StyledHeightWrapper height={height || '100%'} ref={wrapper_ref}>
        <MonacoEditor
          defaultLanguage={language}
          onChange={onChange}
          theme={
            !onChange && !prevent_theme_change
              ? 'my-theme'
              : theme.mode === 'dark'
                ? 'dark'
                : 'light'
          }
          value={value === undefined ? '' : value}
          width="100%"
          height="100px"
          onMount={(editor, monaco) => {
            if (language !== 'text') {
              monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
                noSemanticValidation: true,
                noSyntaxValidation: false,
              });
              // compiler options
              monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
                target: monaco.languages.typescript.ScriptTarget.ES2015,
                allowNonTsExtensions: true,
                allowJs: true,
              });
            }

            if (jsdocs) {
              monaco.languages.typescript.javascriptDefaults.addExtraLib(
                editor_jsdocs[jsdocs],
                'facts.js',
              );
            }

            if (cmdEnter) {
              editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, cmdEnter);
              editor.addCommand(monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter, cmdEnter);
            }

            if (!onChange && !prevent_theme_change) {
              monaco.editor.defineTheme('my-theme', {
                base: theme.mode === 'dark' ? 'vs-dark' : 'vs',
                inherit: true,
                rules: [],
                colors: {
                  'editor.background': theme.colors.surface.base.variant_surface_2,
                },
              });
              monaco.editor.setTheme('my-theme');
            } else if (theme.mode === 'dark') {
              monaco.editor.defineTheme('dark', {
                base: 'vs-dark',
                inherit: true,
                rules: [],
                colors: {
                  'editor.background': theme.colors.surface.base.surface,
                },
              });
              monaco.editor.setTheme('dark');
            }

            editor?.getAction('editor.action.formatDocument')?.run();
            editor_ref.current = editor;
          }}
          options={{
            padding: {
              top: 8,
              bottom: 8,
            },
            automaticLayout: true,
            fontLigatures: true,
            tabSize: 2,
            readOnly: !onChange || disabled,
            domReadOnly: !onChange || disabled,
            guides: {
              indentation: !!onChange,
              highlightActiveIndentation: !!onChange,
            },
            minimap: {
              enabled: false,
            },
            scrollbar: {
              vertical: 'hidden',
              horizontal: 'hidden',
              handleMouseWheel: scroll === false ? false : true,
              alwaysConsumeMouseWheel: scroll === false ? false : true,
            },
            matchBrackets: !onChange ? 'never' : 'always',
            scrollBeyondLastColumn: 0,
            fontSize: small_text ? 12 : 13,
            fontFamily: 'JetBrains Mono, monospace',
            scrollBeyondLastLine: scroll === false ? false : true,
            ...options[display || 'minimal'],
          }}
        />
        {onChange && (
          <>
            <Div
              p={{ x: 3, y: 2 }}
              flex={{ justify: 'flex-end', align: 'end' }}
              style={{ position: 'absolute', left: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
              <Text size="s" muted>
                {language.toUpperCase()}
              </Text>
            </Div>
          </>
        )}
      </StyledHeightWrapper>
      {error && (
        <>
          <Divider />
          <Div p={{ x: 3, y: 2 }}>
            <Text as="span" danger>
              {error}
            </Text>
          </Div>
        </>
      )}
    </>
  );
};

export default Editor;
