import { useContext } from 'react';

import Button from '../../../dashboard/components/common/base/Button';
import Divider from '../../../dashboard/components/common/base/Divider';
import Skeleton from '../../../dashboard/components/common/base/Skeleton';
import Text from '../../../dashboard/components/common/base/Text';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import isEmpty from '../../../dashboard/utils/isEmpty';
import { ConsoleEntriesContext } from '../context/ConsoleEntriesContext';
import { ConsoleResourceContext } from '../context/ConsoleResourceContext';
import { ConsoleSidebarContext } from '../context/ConsoleSidebarContext';
import DestinationForm from './DestinationForm';
import EventAttemptPreview from './EventAttemptPreview';

const ResponseTab: React.FC = () => {
  const { sidebar_data, selected_entry } = useContext(ConsoleSidebarContext);
  const { source, webhook } = useContext(ConsoleResourceContext);
  const { sendRequest } = useContext(ConsoleEntriesContext);

  if (!selected_entry) {
    return (
      <Div p={{ x: 4, t: 4 }}>
        {/* <Heading size="5" as="h3" m={{ b: 1, t: 0 }}>
          Please select an event or request
        </Heading> */}
      </Div>
    );
  }

  if (isEmpty(webhook)) {
    return (
      <>
        <Div p={{ x: 4, t: 4 }}>
          <Text heading as="p" m={{ b: 1 }}>
            Add a Destination
          </Text>
          <Text muted m={{ b: 4 }}>
            Configure a Destination to receive requests and view a response.
          </Text>
        </Div>
        <DestinationForm
          submit_label="Send to Destination"
          onSuccess={(webhook) => sendRequest(selected_entry.id, webhook)}
        />
      </>
    );
  }

  if (sidebar_data && sidebar_data.type !== 'event') {
    return (
      <>
        {sidebar_data.type === 'request' ? (
          <Div flex={{ justify: 'space-between', align: 'center' }} p={4}>
            <Div>
              <Text heading as="h3" m={{ b: 1, t: 0 }}>
                Send the request to your destination
              </Text>
              <Text muted>There's no response for this request yet.</Text>
            </Div>
            <Div m={{ l: 8 }}>
              <Button icon="send" outline onClick={() => sendRequest(selected_entry.id, webhook)}>
                Send
              </Button>
            </Div>
          </Div>
        ) : (
          <Div flex={{ justify: 'space-between', align: 'center' }} p={4}>
            <Div>
              <Text heading as="h3" m={{ b: 1, t: 0 }}>
                Send Request
              </Text>
              <Text muted>Send this sample to your Source URL</Text>
            </Div>
            <Div>
              <Button
                outline
                icon="send"
                onClick={() =>
                  fetch(source.url, {
                    method: 'POST',
                    headers: sidebar_data.entry?.data?.headers,
                    body: JSON.stringify(sidebar_data.entry?.data?.body),
                  })
                }>
                Send
              </Button>
            </Div>
          </Div>
        )}
        <Divider />
        <Div p={4}>
          <Text muted m={{ b: 1 }}>
            Requested URL
          </Text>
          <Skeleton w={{ px: 200 }} h={{ px: 20 }} m={{ b: 6 }} loading={false} />
          <Text muted m={{ b: 1 }}>
            Body
          </Text>
          <Skeleton w={100} h={{ px: 200 }} loading={false} />
        </Div>
      </>
    );
  }

  return <EventAttemptPreview event_id={selected_entry.id} />;
};

export default ResponseTab;
