import { ReactElement, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import useDidMount from '../../hooks/useDidUpdate';
import { Div, StyledUtilsProps } from '../helpers/StyledUtils';
import Badge from './Badge';
import Icon, { IconName } from './Icon';
import Text from './Text';

interface Props extends StyledUtilsProps {
  active_tab: string;
  tabs: {
    icon?: IconName;
    icon_theme?: 'danger' | 'muted' | 'success';
    label: string;
    key: string;
    badge_label?: string | number;
    badge_theme?: 'primary' | 'muted' | 'danger' | 'warning' | 'success';
    to?: string | Partial<Location> | ((location: Location) => Partial<Location> | string);
  }[];
  setActiveTab?: (key: string) => void;
  onTabSelected?: (key: string) => void;

  // Display options
  compact?: boolean;
  border?: boolean;

  // Scroll control
  anchor?: boolean;
  scroll_offset?: number;
}

export const StyledTabs = styled(Div)<{ border?: boolean }>`
  display: flex;
  margin: 0 ${({ theme }) => theme.spacing(1)};
  border-bottom: ${({ theme, border }) => border !== false && theme.border};
`;

export const StyledTab = styled.button<{
  active: boolean;
  inline: boolean;
  compact?: boolean;
}>(
  ({ theme, active, compact }) => css`
    display: flex;
    align-items: center;
    border: none;
    background-color: unset;
    position: relative;
    margin-right: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(compact ? 2 : 3)};
    border-radius: ${theme.radius.large};

    padding: ${theme.spacing(compact ? 1 : 1.5)} ${theme.spacing(compact ? 2 : 3)};

    line-height: ${theme.pxToRem(20)};
    font-weight: ${theme.font_weigths.medium};

    text-decoration: none;
    color: ${active
      ? theme.colors.on.neutral.primary_neutral
      : theme.colors.on.neutral.secondary_neutral};

    &:hover {
      color: ${theme.colors.on.neutral.primary_neutral};
      background-color: ${theme.colors.surface.base.hover.neutral_variant};
      text-decoration: none;
      cursor: pointer;
    }

    ${active &&
    css`
      &:after {
        position: absolute;
        bottom: -${theme.spacing(compact ? 2 : 3)};
        margin-bottom: -1px;
        z-index: 1;
        left: 0;
        right: 0;
        content: '';
        height: 3px;
        background-color: ${theme.colors.on.neutral.primary};
        border-radius: ${theme.radius.normal};
      }
    `};
  `,
);

const StyledLink = styled(RouterLink)`
  text-decoration: none;
`;

const Tabs = ({
  tabs,
  active_tab,
  border,
  anchor,
  scroll_offset,
  setActiveTab,
  onTabSelected,
  compact,
  ...props
}: Props) => {
  const search = typeof window !== 'undefined' ? window.location.search : '';

  useEffect(() => {
    if (!anchor) {
      return;
    }

    let hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const _hash = hash.replace('#', '');
        setActiveTab?.(_hash);
        scrollTo(_hash);
      }, 100);
    }
    const handler = () => {
      hash = window.location.hash;
      const closest = tabs.reduce(
        (current_closest, tab) => {
          const element = document.getElementById(tab.key);
          if (!element) {
            return current_closest;
          }
          const y = element!.getBoundingClientRect().top;
          if (
            !('distance' in current_closest) ||
            Math.abs(current_closest.distance) > Math.abs(y)
          ) {
            return { distance: y, key: tab.key };
          }
          return current_closest;
        },
        {} as { distance: number; key: string } | {},
      );

      if ('key' in closest) {
        if (hash !== `#${closest.key}`) {
          setActiveTab?.(closest.key);
          window.history.replaceState(null, '', `#${closest.key}`);
        }
      }
    };
    document.addEventListener('scroll', handler);
    return () => document.removeEventListener('scroll', handler);
  }, []);

  useDidMount(() => {
    if (anchor && window.location.hash) {
      const _hash = window.location.hash.replace('#', '');
      setActiveTab?.(_hash);
      scrollTo(_hash);
    }
  });

  const scrollTo = (key: string) => {
    const element = document.getElementById(key);
    if (!element) {
      return;
    }
    const y = element.getBoundingClientRect().top + window.pageYOffset + (scroll_offset ?? 0);
    window.scrollTo({ top: y });
  };

  const Wrapper = ({ children, hash }: { children: ReactElement; hash: string }) => {
    if (anchor) {
      return (
        <StyledLink
          to={{
            hash,
            search,
          }}>
          {children}
        </StyledLink>
      );
    }
    return children;
  };

  return (
    <StyledTabs border={border} {...props}>
      {tabs.map((tab) => (
        <Wrapper hash={tab.key} key={tab.key}>
          <StyledTab
            active={tab.key === active_tab}
            as={(tab.to ? RouterLink : 'button') as any}
            to={tab.to || undefined}
            compact={compact}
            onClick={() => {
              onTabSelected && onTabSelected(tab.key);
              anchor && scrollTo(tab.key);
            }}>
            {tab.icon && (
              <Icon
                icon={tab.icon}
                muted={!tab.icon_theme || tab.icon_theme === 'muted'}
                danger={tab.icon_theme === 'danger'}
                success={tab.icon_theme === 'success'}
                left={2}
              />
            )}
            {tab.label}
            {tab.badge_label !== undefined &&
              (tab.badge_theme ? (
                <Badge
                  subtle={tab.badge_theme !== 'muted'}
                  primary={tab.badge_theme === 'primary'}
                  muted={tab.badge_theme === 'muted'}
                  danger={tab.badge_theme === 'danger'}
                  warning={tab.badge_theme === 'warning'}
                  success={tab.badge_theme === 'success'}
                  m={{ l: 2 }}>
                  {tab.badge_label}
                </Badge>
              ) : (
                <Text m={{ l: 2 }} subtitle as="span">
                  {tab.badge_label}
                </Text>
              ))}
          </StyledTab>
        </Wrapper>
      ))}
    </StyledTabs>
  );
};

export default Tabs;
