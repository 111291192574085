import React, { useRef, useState } from 'react';

import { useCopyToClipboard } from '../../utils/copy';
import useLocalStorage from '../hooks/useLocalStorage';
import Button from './base/Button';
import { StyledCard, StyledCardSection } from './base/Card';
import Divider from './base/Divider';
import Link from './base/Link';
import Text from './base/Text';
import { Div } from './helpers/StyledUtils';
import Search from './Search';

type Props = {
  label: string;
  object: object;
  hide_value?: boolean;
  preference_key: string;
  compact?: boolean;
};

const MAX_ROWS = 2;

export const KeyValueRow: React.FC<{
  effective_width: number;
  _key: string;
  value: string;
  hide_value: boolean;
  compact: boolean;
}> = ({ _key, value, effective_width, hide_value, compact }) => {
  const key = _key;
  const ellipsing = effective_width
    ? effective_width < _key.length * 8 || effective_width < value.length * 8
    : false;
  const [expanded, setExpanded] = useState(false);
  const [reveal, setReveal] = useState(false);

  if (compact) {
    return (
      <StyledCardSection key={key} p={{ x: 4, t: ellipsing ? 2 : 3, b: 3 }}>
        <Div flex={{ justify: 'space-between', align: 'center', gap: 2 }} m={{ b: 1 }}>
          <Text
            size={compact ? 's' : 'm'}
            as="p"
            monospace
            muted
            ellipsis={expanded ? false : true}
            style={{ wordBreak: 'break-all' }}
            m={0}>
            {key}
          </Text>
          {ellipsing && (
            <Div>
              <Button
                small={!!compact}
                onClick={() => setExpanded(!expanded)}
                minimal
                icon={expanded ? 'expand_less' : 'expand_more'}
              />
            </Div>
          )}
        </Div>
        <Text
          as="p"
          m={0}
          monospace
          ellipsis={expanded ? false : true}
          size={compact ? 's' : 'm'}
          style={{ wordBreak: 'break-all' }}>
          {hide_value ? new Array(value.length).join('*') : value}
        </Text>
      </StyledCardSection>
    );
  }
  return (
    <StyledCardSection flex>
      <Div w={50} flex={{ justify: 'space-between' }}>
        <Div
          p={{ x: 4, y: 2 }}
          flex
          style={{ width: 'calc(100% - 1px)', boxSizing: 'border-box', wordBreak: 'break-all' }}>
          <Text as="p" monospace muted ellipsis={expanded ? false : true} m={0}>
            {_key}
          </Text>
        </Div>
        <Divider vertical h={100} />
      </Div>
      <Div w={50}>
        <Div
          flex={{ justify: 'space-between', align: expanded ? 'flex-start' : 'center', gap: 2 }}
          p={{ l: 4, r: ellipsing ? 1 : 4, y: ellipsing ? 1 : 2 }}>
          <Text
            onMouseEnter={hide_value ? () => setReveal(true) : undefined}
            onMouseLeave={hide_value ? () => setReveal(false) : undefined}
            as="p"
            m={{ t: expanded ? 1 : 0, b: 0 }}
            monospace
            ellipsis={expanded ? false : true}
            style={{ wordBreak: 'break-all' }}>
            {hide_value && !reveal ? new Array(value.length).join('*') : value}
          </Text>
          {ellipsing && (
            <Div>
              <Button
                small
                onClick={() => setExpanded(!expanded)}
                minimal
                icon={expanded ? 'expand_less' : 'expand_more'}
              />
            </Div>
          )}
        </Div>
      </Div>
    </StyledCardSection>
  );
};

const KeyValueTable: React.FC<Props> = ({ hide_value, preference_key, object, label, compact }) => {
  const [expanded, setExpanded] = useLocalStorage(`pref:${preference_key}:expanded`, false);
  const ref = useRef<HTMLDivElement>(null);
  const [search_term, setSearchValue] = useState('');
  const copy = useCopyToClipboard();

  const effective_width = ref.current && ref.current.offsetWidth - 2 - 32;
  const filtered_rows = Object.entries(object)
    .sort((a, b) => {
      if (a === b) {
        return 0;
      }
      return a < b ? -1 : 1;
    })
    .filter(
      ([key, value]) =>
        !search_term ||
        `${key}${value}`.toLocaleLowerCase().indexOf(search_term.toLowerCase()) >= 0,
    );
  return (
    <StyledCard>
      <StyledCardSection p={{ x: 4, y: 2 }} flex={{ justify: 'space-between' }}>
        <Div flex={{ align: 'center', grow: true }}>
          <Text subtitle size={compact ? 's' : 'm'}>
            {label}
          </Text>
        </Div>
        {!compact ? (
          <Div flex={{ align: 'center', gap: 2 }}>
            <Search small value={search_term} onChange={setSearchValue} />{' '}
            <Button
              small
              minimal
              icon="copy"
              onClick={() => copy(JSON.stringify(object, null, 2))}
            />
          </Div>
        ) : (
          <Button small minimal icon="copy" onClick={() => copy(JSON.stringify(object, null, 2))} />
        )}
      </StyledCardSection>
      {compact ? (
        <StyledCardSection ref={ref}>
          <Search small borderless value={search_term} onChange={setSearchValue} />
        </StyledCardSection>
      ) : (
        <StyledCardSection muted flex>
          <Div w={50} flex={{ justify: 'space-between' }} ref={ref}>
            <Text subtitle muted p={{ x: 4, y: 1 }}>
              Key
            </Text>
            <Divider vertical h={100} />
          </Div>
          <Div w={50}>
            <Text subtitle muted p={{ x: 4, y: 1 }}>
              Value
            </Text>
          </Div>
        </StyledCardSection>
      )}
      {filtered_rows.length === 0 && (
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text muted size={compact ? 's' : 'm'}>
            Empty
          </Text>
        </StyledCardSection>
      )}
      {filtered_rows
        .filter((v, i) => expanded || !MAX_ROWS || i < MAX_ROWS)
        .map(([key, value]) => (
          <KeyValueRow
            compact={!!compact}
            key={key}
            effective_width={effective_width || 0}
            _key={key}
            value={value}
            hide_value={!!hide_value}
          />
        ))}
      {filtered_rows.length > MAX_ROWS && (
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Link
            as="button"
            small={compact}
            icon={expanded ? 'collapse_all' : 'expand_all'}
            flex={{ align: 'center' }}
            onClick={() => setExpanded(!expanded)}>
            {!expanded ? `View All ${filtered_rows.length}` : 'Collapse'}
          </Link>
        </StyledCardSection>
      )}
    </StyledCard>
  );
};

export default KeyValueTable;
