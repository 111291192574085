import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import useComponentDefaultProps from '../../hooks/useComponentDefaultProps';
import { Div, StyledUtilsProps } from '../helpers/StyledUtils';

type Variant = 'rectangle' | 'circle' | 'square' | 'button';
interface SkeletonProps extends StyledUtilsProps {
  variant?: Variant;
  border_radius?: 'small' | 'normal' | 'full';

  large?: boolean;
  medium?: boolean;
  small?: boolean;
  xsmall?: boolean;

  loading?: boolean;
}

const default_props: Partial<SkeletonProps> = {
  variant: 'rectangle',
  loading: true,
};

export const pulse_keyframe = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const getSize = (variant: Variant, size: string) => {
  return css`
    height: ${size};
    ${(variant === 'square' || variant === 'circle') &&
    css`
      width: ${size};
    `};
  `;
};

interface StyledSkeletonProps extends SkeletonProps {
  $loading?: boolean;
}

const StyledSkeleton = styled(Div)<StyledSkeletonProps>(
  ({ theme, ...props }) => css`
    position: relative;
    overflow: hidden;
    border-radius: ${({ theme }) => theme.radius.small};

    ${theme.optionsToCss<SkeletonProps>(
      {
        large: css`
          border-radius: ${({ theme }) => theme.radius.normal};
          ${getSize(props.variant || 'rectangle', theme.pxToRem(40))};
        `,
        medium: css`
          ${getSize(props.variant || 'rectangle', theme.pxToRem(32))};
        `,
        small: css`
          ${getSize(props.variant || 'rectangle', theme.pxToRem(22))};
        `,
        xsmall: css`
          ${getSize(props.variant || 'rectangle', theme.pxToRem(16))};
        `,
      },
      props,
      'small',
    )}

    ${props.border_radius === 'small' &&
    css`
      border-radius: ${({ theme }) => theme.radius.small};
    `}
    ${props.border_radius === 'normal' &&
    css`
      border-radius: ${({ theme }) => theme.radius.normal};
    `}
    ${props.border_radius === 'full' &&
    css`
      border-radius: 25px;
    `}

    ${props.variant === 'circle' &&
    css`
      border-radius: 50%;
    `};

    &::before {
      content: '';
      position: absolute;
      background: ${theme.colors.surface.base.skeleton};
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      ${props.$loading &&
      css`
        animation: ${pulse_keyframe} 1.5s ease-in-out 0.5s infinite;
      `};
    }
  `,
);

const Skeleton: React.FC<SkeletonProps> = (props) => {
  props = useComponentDefaultProps(default_props, props);
  const { loading, ...other_props } = props;
  return <StyledSkeleton {...other_props} $loading={loading} />;
};

export default Skeleton;
