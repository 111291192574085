import { createContext, PropsWithChildren, useCallback, useContext, useEffect } from 'react';
import useSWR from 'swr';

import { Event } from '../../../../../typings/Event.interface';
import { ShortEventData } from '../../../../../typings/EventData.interface';
import { Request } from '../../../../../typings/Request.interface';
import APIMethodKeys from '../../../dashboard/client/APIMethodKeys';
import { GlobalContext } from '../../../dashboard/components/contexts/GlobalContext';
import useLocalStorage from '../../../dashboard/components/hooks/useLocalStorage';
import Sentry from '../Sentry';
import { ConsoleGlobalContext } from './ConsoleGlobalContext';

interface SidebarData {
  type: 'request' | 'event' | 'destination';
  entry?: { data: ShortEventData } | Event | Request;
}

interface SelectedEntry {
  id: string;
}

export const ConsoleSidebarContext = createContext<{
  sidebar_data: SidebarData | null;
  selected_entry: SelectedEntry | null;
  closeSidebar: () => void;
  selectEventOrRequest: (id: string, entry: Event | Request) => void;
  editDestination: () => void;
}>({
  sidebar_data: null,
  selected_entry: null,
  selectEventOrRequest: () => null,
  closeSidebar: () => null,
  editDestination: () => null,
});

let query_event_id: string | null;

try {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    query_event_id = params.get('event_id');
    params.delete('event_id');
    const new_params_string = params.toString();
    if (query_event_id) {
      let url = window.location.pathname;
      if (new_params_string.length > 0) {
        url += `?${new_params_string}`;
      }
      window.history.replaceState({}, document.title, url);
    }
  }
} catch (e) {
  Sentry.captureException(e);
}

export const ConsoleSidebarContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team } = useContext(ConsoleGlobalContext);
  const [sidebar_data, setSidebarData] = useLocalStorage<SidebarData | null>(
    `${team.id}:console:state:sidebar_data`,
    null,
    true,
  );
  const [selected_entry, setSelectedEntry] = useLocalStorage<SelectedEntry | null>(
    `${team.id}:console:state:selected_entry`,
    null,
    true,
  );

  const selectEventOrRequest = useCallback((id: string, entry: Request | Event) => {
    setSidebarData({ type: id.indexOf('evt_') === 0 ? 'event' : 'request', entry });
    setSelectedEntry({ id });
  }, []);

  const { data: query_event } = useSWR(
    query_event_id && APIMethodKeys.events.get(query_event_id),
    () => HookdeckAPI.events.get(query_event_id || ''),
  );

  useEffect(() => {
    if (query_event !== undefined) {
      setSelectedEntry({
        id: query_event_id || '',
      });
      setSidebarData({
        type: 'event',
        entry: query_event,
      });
    }
  }, [query_event]);

  const context = {
    sidebar_data,
    selected_entry,
    closeSidebar: () => {
      setSidebarData(null);
      setSelectedEntry(null);
    },
    selectEventOrRequest: selectEventOrRequest,
    editDestination: () => setSidebarData({ type: 'destination' }),
  };

  return (
    <ConsoleSidebarContext.Provider value={context}>{children}</ConsoleSidebarContext.Provider>
  );
};
