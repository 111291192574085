import qs from 'qs';

export const toArray = (value: string | string[]) => {
  if (value === undefined) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};

export const extractFromArray = (
  value: string | object | (string | object)[] | undefined,
  index = 0,
) => {
  if (value === undefined) {
    return undefined;
  }
  return Array.isArray(value) ? value[index] : value;
};

export const roundNumber = (value: number, precision = 2): number => {
  return Number(Math.round((value + 'e+' + precision) as any) + 'e-' + precision);
};

export const parseQuery = (search) => {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  });
};

export const stringifyQuery = (search) => {
  return qs.stringify(search, {
    encodeValuesOnly: true,
    strictNullHandling: true,
  });
};

export const fieldName = (string: string, prefix?: string) => {
  if (!prefix) {
    return string;
  }
  return `${prefix}.${string}`;
};

export const pluralize = (count: number, noun: string, suffix = 's') => {
  const has_suffix = noun.indexOf(suffix, noun.length - suffix.length) !== -1;

  if (count <= 1) {
    if (has_suffix) {
      return noun.slice(0, -suffix.length);
    }
    return noun;
  }
  if (!has_suffix) {
    return `${noun}${suffix}`;
  }
  return noun;
};

export const generateUEID = (): string => {
  const first = (Math.random() * 46656) | 0;
  const second = (Math.random() * 46656) | 0;
  return ('000' + first.toString(36)).slice(-3) + ('000' + second.toString(36)).slice(-3);
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatAmountOfTime = (minutes: number): string => {
  if (minutes === 1) {
    return `${minutes} minute`;
  }
  if (minutes < 120) {
    return `${minutes} minutes`;
  }
  if (minutes === 120) {
    return `2 hours`;
  }
  if (minutes >= 120) {
    const hours = Math.floor(minutes / 60);
    const remaining_minutes = minutes - hours * 60;
    if (minutes > 0) {
      return `${hours}h ${remaining_minutes} min`;
    }
    return `${hours} hours`;
  }
  return minutes.toString();
};

export const isValidUrl = (url: string) => {
  // Check before even instantiating `URL` because it can otheriwse
  // allow `http:something` to be parsed as valid.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return false;
  }

  // The URL constructor encodes spaces instead of throwing an error
  // but our backend uses Joi and will reject it.
  if (url.trim().includes(' ')) {
    return false;
  }

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string || typeof string !== 'string') return string;

  return string.charAt(0).toUpperCase() + string.slice(1);
};
