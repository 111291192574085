import { AttemptErrorCodes } from '../../../../typings/EventAttempt.interface';
import { Subscription } from '../../../../typings/Subscription.interface';

export const getErrorCodeLabels = (
  subscription?: Subscription,
): Record<AttemptErrorCodes, string> => ({
  // Request was not attempted
  CANCELLED:
    'The delivery has been cancelled either manually, by archiving or deleting the associated connection.',
  DATA_ARCHIVED: 'The event data has been archived.',
  BULK_RETRY_CANCELLED: 'Bulk retry that triggered this attempt was cancelled.',
  // Request was attempted
  DNS_LOOKUP_FAILED: 'The DNS lookup for your destination domain failed or timed out.',
  CONNECTION_REFUSED:
    'The request to your destination could not be made because the connection was refused.',
  CONNECTION_RESET:
    'The request to your destination could not be made because the connection was forcibly closed by your server.',
  NOT_FOUND:
    'The request to your destination could not be made because the domain or host was not found or unavailable.',
  MISSING_URL:
    'Your destination does not have a HTTP URL configured. It can only receive attempts on the CLI.',
  CLI: 'The attempt failed to be forwarded to your local server by the CLI. Check your console for more details.',
  CLI_UNAVAILABLE: 'The request to your CLI could not be made because the CLI was not connected.',
  TIMEOUT: `The request to your destination exceeded the ${
    subscription?.attempt_timeout || 60
  } seconds timeout. The request might have still been processed on your destination.`,
  SELF_SIGNED_CERT:
    'Your destination uses a self-signed SSL certificate. Hookdeck only supports valid, verifiable certificates.',
  ERR_TLS_CERT_ALTNAME_INVALID: "Your destination's SSL certificate is not valid.",
  ERR_SSL_WRONG_VERSION_NUMBER:
    'Indicates a mismatch between the SSL/TLS version expected by your destination and the version supported by the server.',
  SSL_ERROR_CA_UNKNOWN:
    'The CA for the certificate of the server in your destination is unknown. This is generally a server configuration error, and needs to be resolved at the destination server.',
  SSL_CERT_EXPIRED: "Your destination's SSL certificate is expired.",
  TTL_EXPIRED:
    'There seems to be a circular dependency in your connection configuration. Ensure that your source is not set to the same value as your destination.',
  HOST_UNREACHABLE: 'Your destination host is unreachable.',
  PROTOCOL_ERROR:
    'A protocol error occurred during our attempt to deliver the request. This usually indicates a network error.',
  SOCKET_CLOSED: 'The destination socket was closed during the request transmission.',
  NETWORK_UNREACHABLE: "Your destination's network is unreachable.",
  OAUTH2_HANDSHAKE_FAILED:
    'The OAuth2 handshaking process failed. This usually indicates wrong configuration or an error with the Oauth2 server.',
  UNKNOWN:
    'An unknown error occured while sending a request to your destination. The team has been notified and is investigating.',
  PAYLOAD_MISSING: 'The payload was missing from the request.',
  CANCELLED_PAST_RETENTION:
    'The delivery has been cancelled because it was past the retention period of your project.',
});
