const WEBSITE_URL = process.env.WEBSITE_URL || `https://${process.env.ROOT_DOMAIN}`;
const WEBSITE_URI = new URL(WEBSITE_URL);

export const appendTracking = (link: string) => {
  const url = new URL(link);
  if (url.hostname === WEBSITE_URI.hostname) {
    url.searchParams.append('ref', 'hookdeck-console');
    return url.toString();
  }
  return link;
};
