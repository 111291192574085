import LogRocket from 'logrocket';
import ReactDOM from 'react-dom/client';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import { ConsoleProvider } from './types';

if (VITE_APP_ENV === 'production') {
  LogRocket.init('hafoob/hookdeck', {
    rootHostname: VITE_ROOT_DOMAIN,
  });
}

let query_provider;
try {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    query_provider = params.get('provider');
    params.delete('provider');
    const new_params_string = params.toString();
    if (query_provider) {
      let url = window.location.pathname;
      if (new_params_string.length > 0) {
        url += `?${new_params_string}`;
      }
      window.history.replaceState({}, document.title, url);
    }
  }
} catch (error) {
  console.error(error);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ErrorBoundary LogRocket={LogRocket}>
    <App
      context="fullpage"
      telemetry={true}
      LogRocket={LogRocket}
      provider={(query_provider as ConsoleProvider) || undefined}
    />
  </ErrorBoundary>,
);
