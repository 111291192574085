import { StyledCard, StyledCardSection } from '../base/Card';
import Button from '../base/Button';
import Text from '../base/Text';
import { useCopyToClipboard } from '../../../utils/copy';

type Props = {
  path: string;
  compact?: boolean;
};

const RequestPath: React.FC<Props> = ({ path, compact }) => {
  const copyToClipboard = useCopyToClipboard();

  return (
    <StyledCard>
      <StyledCardSection flex={{ justify: 'space-between', align: 'center' }} p={{ y: 2, x: 4 }}>
        <Text subtitle size={compact ? 's' : 'm'}>
          Path
        </Text>
        <Button small onClick={() => copyToClipboard(path)} icon="copy" minimal />
      </StyledCardSection>
      <StyledCardSection muted p={{ x: 4, y: 3 }}>
        <Text
          monospace
          muted={path === '/'}
          word_break="break-all"
          as="span"
          size={compact ? 's' : 'm'}>
          {path}
        </Text>
      </StyledCardSection>
    </StyledCard>
  );
};

export default RequestPath;
