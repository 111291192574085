/**
 * @see https://github.com/tamagui/tamagui/blob/fe036f364daf50e65357fed0c15d5c804e5f0890/packages/web/src/helpers/withStaticProperties.tsx#L1C1-L9C2
 */
export const withStaticProperties = function <A extends Function, B>(
  component: A,
  staticProps: B,
): A & B {
  // clone if object to stay immutable
  const next = typeof component === 'function' ? component : { ...(component as any) };
  Object.assign(next, staticProps);
  return next as A & B;
};
