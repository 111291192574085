import { useContext } from 'react';
import { ConsoleEntriesContext } from '../context/ConsoleEntriesContext';
import EmptyState from './EmptyState';
import ConsoleEntriesTable from './EntriesTable';

const EventSection = () => {
  const { entries } = useContext(ConsoleEntriesContext);

  if (!entries) {
    return null;
  }

  const has_entries = entries.length > 0;

  return (
    <>
      {has_entries && <ConsoleEntriesTable />}
      {!has_entries && <EmptyState />}
    </>
  );
};

export default EventSection;
