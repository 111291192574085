import styled from 'styled-components';
import { Div } from './StyledUtils';

const StyledEllipsisOverflow = styled(Div)`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export default StyledEllipsisOverflow;
