import Text from './base/Text';
import { format } from 'date-fns';

const DisplayDate: React.FC<{ date: string | null; empty_string?: string; size?: 's' | 'm' }> = ({
  date,
  empty_string = '–',
  size = 's',
}) => {
  return (
    <Text monospace size={size} muted as="p" m={{ b: 0 }}>
      {date ? format(new Date(date), `yyyy-MM-dd HH:mm:ss`) : empty_string}
    </Text>
  );
};

export default DisplayDate;
