/**
 * Filters out x-hookdeck headers.
 *
 * @param headers - header map
 * @returns filtered header map
 */
export default function filteredHeaders(headers: object): object {
  const newHeaders = {};

  if (!headers) return newHeaders;

  Object.entries(headers).forEach(([header, value]) => {
    if (!header.startsWith('x-hookdeck')) {
      newHeaders[header] = value;
    }
  });
  return newHeaders;
}
