import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../helpers/StyledUtils';
import Icon, { IconName } from './Icon';

interface Props extends StyledUtilsProps {
  subtle?: boolean;

  primary?: boolean;
  danger?: boolean;
  success?: boolean;
  muted?: boolean;
  warning?: boolean;

  small?: boolean;

  monospace?: boolean;

  icon?: IconName;
}

const StyledBadge = styled(Div)<Props>(
  ({ theme, small, ...props }) => css`
    position: relative;
    border-radius: ${theme.radius.small};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 0;

    font-variant-numeric: tabular-nums;

    border-radius: ${theme.radius.small};
    font-size: ${theme.pxToRem(small ? 12 : 13)};
    min-width: ${theme.pxToRem(20)};
    line-height: ${theme.pxToRem(20)};
    min-height: 20px;
    padding: 0 ${theme.spacing(1)};
    font-weight: ${theme.font_weigths.medium};

    ${props.monospace &&
    css`
      font-family:
        JetBrains Mono,
        monospace;
    `}

    ${!props.subtle &&
    theme.optionsToCss(
      {
        primary: css`
          color: ${theme.colors.on.hue.primary};
          background-color: ${theme.colors.surface.base.primary};
        `,
        muted: css`
          color: ${theme.colors.on.neutral.primary_neutral};
          background-color: ${theme.colors.surface.container.neutral};
        `,
        danger: css`
          color: ${theme.colors.on.hue.danger};
          background-color: ${theme.colors.surface.base.danger};
        `,
        success: css`
          color: ${theme.colors.on.hue.success};
          background-color: ${theme.colors.surface.base.success};
        `,
        warning: css`
          color: ${theme.colors.on.hue.warning};
          background-color: ${theme.colors.surface.base.warning};
        `,
      },
      props,
      'primary',
    )}

    ${props.subtle &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${theme.radius.small};
      }
    `}

    ${props.subtle &&
    theme.optionsToCss(
      {
        primary: css`
          color: ${theme.colors.on.hue_container.primary};
          background-color: ${theme.colors.surface.container.primary};
          &::after {
            border: 1px solid ${theme.colors.outline.primary};
          }
        `,
        muted: css`
          color: ${theme.colors.on.neutral.primary_neutral};
          background-color: ${theme.colors.surface.base.surface};
          &::after {
            border: 1px solid ${theme.colors.outline.neutral};
          }
        `,
        danger: css`
          color: ${theme.colors.on.hue_container.danger};
          background-color: ${theme.colors.surface.container.danger};
          &::after {
            border: 1px solid ${theme.colors.outline.danger};
          }
        `,
        success: css`
          color: ${theme.colors.on.hue_container.success};
          background-color: ${theme.colors.surface.container.success};
          &::after {
            border: 1px solid ${theme.colors.outline.success};
          }
        `,
        warning: css`
          color: ${theme.colors.on.hue_container.warning};
          background-color: ${theme.colors.surface.container.warning};
          &::after {
            border: 1px solid ${theme.colors.outline.warning};
          }
        `,
      },
      props,
      'primary',
    )}
  `,
);

const Badge: React.FC<PropsWithChildren<Props>> = ({ children, icon, ...props }) => (
  <StyledBadge as="span" {...props}>
    {icon && <Icon icon={icon} muted={props.muted} left={children !== undefined ? 1 : 0} />}
    {children}
  </StyledBadge>
);

export default Badge;
