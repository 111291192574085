import styled, { css } from 'styled-components';

import { EventStatus as IEventStatus } from '../../../../../typings/Event.interface';
import { AttemptStatus as IAttemptStatus } from '../../../../../typings/EventAttempt.interface';
import Badge from './base/Badge';

const status_map: Record<IEventStatus | IAttemptStatus, { theme_color: string; text?: string }> = {
  SUCCESSFUL: {
    theme_color: 'success',
    text: 'Successful',
  },
  FAILED: {
    theme_color: 'danger',
    text: 'Failed',
  },
  QUEUED: {
    theme_color: 'primary',
    text: 'Pending',
  },
  HOLD: {
    theme_color: 'warning',
    text: 'Paused',
  },
  SCHEDULED: {
    theme_color: 'warning',
    text: 'Scheduled',
  },
};

const StyledStatusBadge = styled(Badge)<{ status: IEventStatus }>(
  ({ theme, status }) => css`
    gap: ${theme.spacing(1)};
    font-family:
      JetBrains Mono,
      monospace;

    &::before {
      content: '';
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin: ${theme.spacing(1)};
      background-color: ${theme.colors.on.hue_container[status_map[status].theme_color]};
    }
  `,
);

export const Status: React.FC<{
  status: IEventStatus | IAttemptStatus;
  response_status: number | null;
  show_response?: boolean;
  small?: boolean;
}> = ({ status, response_status, show_response = true, small }) => {
  let text = status_map[status].text;
  if (show_response && (status === 'FAILED' || status === 'SUCCESSFUL')) {
    if (response_status) {
      text = String(response_status);
    }
  }
  return (
    <StyledStatusBadge
      small={small}
      status={status}
      {...{ [status_map[status].theme_color]: true }}
      subtle>
      {text}
    </StyledStatusBadge>
  );
};
