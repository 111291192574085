import { stringifyQuery } from '../utils';

const appendFilters = (key: string, filters?: object) => {
  if (!filters) return key;
  return `${key}?${stringifyQuery(filters)}`;
};

const APIMethodKeys = {
  billing: {
    getThroughputTiers: (): string => '/internal/throughput/tiers',
    getToken: (): string => '/internal/billing/token',
    getUsage: (filters?: object): string => appendFilters('/internal/billing/usage', filters),
    getPlans: (filters?: object): string => appendFilters('/internal/billing/plans', filters),
    getCard: (): string => `/internal/billing/card`,
    getInvoices: (): string => `/internal/billing/invoices`,
    getBillingEmail: (): string => `/internal/billing/email`,
    getSubscriptionDetails: (): string => `/internal/billing/subscription/details`,
  },
  users: {
    findByEmail: (email: string): string => appendFilters('/users', { email }),
  },
  webhooks: {
    count: (filters?: object): string => appendFilters('/webhooks/count', filters),
    get: (id: string): string => appendFilters(`/webhooks/${id}`),
    list: (filters?: object): string => appendFilters('/webhooks', filters),
  },
  attempts: {
    get: (id: string): string => appendFilters(`/attempts/${id}`),
    list: (filters?: object): string => appendFilters('/attempts', filters),
  },
  destinations: {
    get: (id: string): string => appendFilters(`/destinations/${id}`),
    list: (filters?: object): string => appendFilters('/destinations', filters),
  },
  events: {
    get: (id: string, options?: { event_request?: 'original' }): string =>
      appendFilters(`/events/${id}`, options),
    getRawBody: (id: string): string => `/events/${id}/raw_body`,
    list: (filters?: object): string => appendFilters('/events', filters),
  },
  ignored_events: {
    list: (filters?: object): string => appendFilters('/ignored-events', filters),
  },
  requests: {
    get: (id: string): string => appendFilters(`/requests/${id}`),
    getRawBody: (id: string): string => `/requests/${id}/raw_body`,
    list: (filters?: object): string => appendFilters('/requests', filters),
    countEvents: (id: string, filters?: object): string =>
      appendFilters(`/requests/${id}/events/count`, filters),
    listEvents: (id: string, filters?: object): string =>
      appendFilters(`/requests/${id}/events`, filters),
    listIgnoredEvents: (id: string, filters?: object): string =>
      appendFilters(`/requests/${id}/ignored_events`, filters),
  },
  bookmarks: {
    get: (id: string): string => appendFilters(`/bookmarks/${id}`),
    getRawBody: (id: string): string => `/bookmarks/${id}/raw_body`,
    list: (filters?: object): string => appendFilters('/bookmarks', filters),
  },
  sources: {
    get: (id: string, filters?: object): string => appendFilters(`/sources/${id}`, filters),
    list: (filters?: object): string => appendFilters('/sources', filters),
  },
  issues: {
    get: (id: string): string => appendFilters(`/issues/${id}`),
    list: (filters?: object): string => appendFilters('/issues', filters),
    count: (filters?: object): string => appendFilters(`/issues/count`, filters),
  },
  issue_triggers: {
    get: (id: string): string => appendFilters(`/issue-triggers/${id}`),
    list: (filters?: object): string => appendFilters('/issue-triggers', filters),
    count: (filters?: object): string => appendFilters(`/issue-triggers/count`, filters),
  },
  team_integrations: {
    list: (filters?): string => appendFilters(`/session/team-integrations`, filters),
  },
  transformations: {
    list: (filters?): string => appendFilters(`/transformations`, filters),
    get: (id: string): string => `/transformations/${id}`,
    listExecutions: (id, filters?: object): string =>
      appendFilters(`/transformations/${id}/executions`, filters),
    getExecution: (id, execution_id): string =>
      appendFilters(`/transformations/${id}/executions/${execution_id}`),
    hasConnections: (id: string): string => `/transformations/${id}/connections`,
  },
  teams: {
    listAll: (): string => `/teams`,
    listCustomDomains: (id: string): string => `/teams/${id}/custom_domains`,
    listMembers: (id: string): string => `/teams/${id}/members`,
  },
  organizations: {
    listAll: (): string => `/organizations`,
    listMembers: (id: string): string => `/organizations/${id}/members`,
    listMemberInvites: (id: string): string => `/organizations/${id}/invites`,
    validateInviteToken: (id: string): string => `/internal/validate_invite_token/${id}`,
  },
  bulk: {
    events: {
      count: (filters?: object): string => appendFilters('/bulk/events/count', filters),
      list: (filters?: object): string => appendFilters('/bulk/events', filters),
      get: (id: string): string => `/bulk/events/${id}`,
      plan: (filters?: object): string => appendFilters('/bulk/events/plan', filters),
    },
    requests: {
      count: (filters?: object): string => appendFilters('/bulk/requests/count', filters),
      list: (filters?: object): string => appendFilters('/bulk/requests', filters),
      get: (id: string): string => `/bulk/requests/${id}`,
      plan: (filters?: object): string => appendFilters('/bulk/requests/plan', filters),
    },
    ignored_events: {
      count: (filters?: object): string => appendFilters('/bulk/ignored-events/count', filters),
      list: (filters?: object): string => appendFilters('/bulk/ignored-events', filters),
      get: (id: string): string => `/bulk/ignored-events/${id}`,
      plan: (filters?: object): string => appendFilters('/bulk/ignored-events/plan', filters),
    },
  },
  cli_clients: {
    list: (filters?: object): string => appendFilters('/cli', filters),
    getSessions: (filters?: object): string => appendFilters('/cli-sessions', filters),
  },
  session: {
    me: (): string => '/session/me',
    context: (): string => '/session/context',
    getCurrentApiKey: (): string => '/session/api_key',
    getCurrentSigningSecret: (): string => '/session/signing_secret',
  },
  views: {
    list: (filters?: object): string => appendFilters('/views', filters),
  },
};

export default APIMethodKeys;
