import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import Button, { ClickableArea } from '../../../dashboard/components/common/base/Button';
import { StyledCard, StyledCardSection } from '../../../dashboard/components/common/base/Card';
import Divider from '../../../dashboard/components/common/base/Divider';
import Icon from '../../../dashboard/components/common/base/Icon';
import Link from '../../../dashboard/components/common/base/Link';
import Text from '../../../dashboard/components/common/base/Text';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import { useToasts } from '../../../dashboard/components/common/Toast';
import useLocalStorage from '../../../dashboard/components/hooks/useLocalStorage';
import { ConsoleEntriesContext } from '../context/ConsoleEntriesContext';
import { ConsoleGlobalContext } from '../context/ConsoleGlobalContext';
import { ConsoleResourceContext } from '../context/ConsoleResourceContext';

const StyledInputWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0;
    position: relative;
    &:has(input:focus) {
      border-color: ${theme.colors.surface.base.primary};
      outline: 2px solid ${theme.colors.outline.primary};
      outline-offset: -1px;
    }

    > button {
      position: absolute;
      top: 0;
      right: 4px;
      z-index: 1;
    }

    > svg {
      position: absolute;
      pointer-events: none;
      top: 6px;
      right: 0;
    }
  `,
);

const StyledInput = styled.input(
  ({ theme }) => css`
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    &:focus {
      border: none;
      outline: none;
    }
  `,
);

const StyledResultsContainer = styled(Div)`
  max-height: 248px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSelectableClickableArea = styled(ClickableArea)<{ selected: boolean }>(
  ({ theme, selected }) => css`
    ${selected &&
    css`
      background-color: ${theme.colors.surface.container.primary};

      &:hover {
        background-color: ${theme.colors.surface.container.primary};
      }
    `}
  `,
);

const SampleWebhooks: React.FC = () => {
  const { entries } = useContext(ConsoleEntriesContext);
  const { provider } = useContext(ConsoleGlobalContext);
  const { source } = useContext(ConsoleResourceContext);

  const [active_provider, setActiveProvider] = useLocalStorage<string | undefined>(
    'pref:samples:active-provider',
    provider || 'shopify',
  );

  const [active_topic, setActiveTopic] = useLocalStorage<string | undefined>(
    'pref:samples:active-topic',
    provider,
  );

  useEffect(() => {
    if (provider) {
      setActiveProvider(provider);
      if (active_provider !== provider) {
        setActiveTopic('');
      }
    }
  }, [provider]);

  const [search_team_provider, setSearchTermProvider] = useState<string>('');
  const [search_team_topic, setSearchTermTopic] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToasts();

  const { data: providers } = useSWR<Record<string, { label: string; latest_version: string }>>(
    '/providers.json',
    (path) =>
      fetch(`${VITE_SAMPLES_URL}${path}`)
        .then((res) => res.json())
        .then((res) =>
          Object.entries(res)
            .sort((a, b) => (b[0] === 'shopify' ? 1 : a[0] > b[0] ? -1 : 1))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
        ),
  );

  useEffect(() => {
    if (providers && !active_provider) {
      setActiveProvider(Object.keys(providers)[0]);
    }
  }, [providers, active_provider]);

  useEffect(() => {
    if (entries?.length) {
      setLoading(false);
    }
  }, [entries]);

  const provider_config = providers && active_provider ? providers[active_provider] : undefined;

  const { data: topics } = useSWR<
    Record<string, { headers: Record<string, string>; body: object }>
  >(
    provider_config && `/providers/${active_provider}/${provider_config.latest_version}.json`,
    (path) => fetch(`${VITE_SAMPLES_URL}${path}`).then((res) => res.json()),
  );

  const filtered_providers =
    providers &&
    Object.entries(providers)
      .filter(
        ([, provider]) =>
          !search_team_provider ||
          provider.label.toLowerCase().includes(search_team_provider.toLowerCase()),
      )
      .sort(([key]) => (provider && key === active_provider ? -1 : 1));

  const filtered_topic =
    topics &&
    Object.entries(topics).filter(
      ([topic]) =>
        !search_team_topic || topic.toLowerCase().includes(search_team_topic.toLowerCase()),
    );

  return (
    <StyledCard style={{ maxWidth: '804px' }}>
      <StyledCardSection
        muted
        p={{ x: 4, y: 2 }}
        flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center' }}>
          <Icon icon="export_notes" left />
          <Text subtitle as="h2" m={0}>
            Example Webhooks
          </Text>
        </Div>
        <div>
          <Button
            onClick={() => {
              setLoading(true);
              fetch(source.url, {
                method: 'POST',
                headers: topics && active_topic ? topics[active_topic].headers : {},
                body: JSON.stringify(topics && active_topic ? topics[active_topic].body : {}),
              }).then(() => {
                addToast('success', 'Sent sample webhook to source URL');
              });
            }}
            primary
            icon={loading ? 'loading' : 'send'}
            p={loading ? { x: 5 } : {}}
            small
            disabled={!active_topic}>
            {!loading && 'Send'}
          </Button>
        </div>
      </StyledCardSection>
      <StyledCardSection flex>
        <Div style={{ width: '268px' }}>
          <StyledInputWrapper>
            <StyledInput
              value={search_team_provider}
              onChange={(e) => setSearchTermProvider(e.target.value)}
              placeholder="Sample Webhook Provider"
            />
            {search_team_provider.length > 0 ? (
              <Button
                icon="close"
                p={1}
                m={1}
                minimal
                onClick={() => setSearchTermProvider('')}
                small
              />
            ) : (
              <Icon icon="search" muted left={3} />
            )}
          </StyledInputWrapper>
          <Divider />
          <StyledResultsContainer p={3}>
            {filtered_providers?.length === 0 && (
              <Text muted m={1}>
                No providers match your search term.
              </Text>
            )}
            {filtered_providers?.map(([key, provider]) => (
              <StyledSelectableClickableArea
                selected={key === active_provider}
                onClick={() => {
                  setActiveProvider(key);
                  setActiveTopic('');
                }}
                rounded
                p={{ x: 3, y: 2 }}
                m={{ b: 2 }}
                key={key}>
                <Text subtitle primary={key === active_provider}>
                  {provider.label}
                </Text>
              </StyledSelectableClickableArea>
            ))}
          </StyledResultsContainer>
        </Div>
        <Divider vertical />
        <Div flex={{ grow: true, direction: 'column' }} style={{ width: '452px' }}>
          <StyledInputWrapper>
            <StyledInput
              value={search_team_topic}
              onChange={(e) => setSearchTermTopic(e.target.value)}
              placeholder="Sample Webhook Type"
            />
            {search_team_topic.length > 0 ? (
              <Button icon="close" m={1} minimal onClick={() => setSearchTermTopic('')} small />
            ) : (
              <Icon icon="search" muted left={3} />
            )}
          </StyledInputWrapper>
          <Divider />
          <StyledResultsContainer p={3}>
            {filtered_topic?.length === 0 && provider_config && (
              <Text muted m={1}>
                No topics match your search term for the selected provider {provider_config.label}.
              </Text>
            )}
            {filtered_topic?.map(([topic]) => (
              <StyledSelectableClickableArea
                selected={active_topic === topic}
                onClick={() => setActiveTopic(topic)}
                rounded
                key={topic}
                flex={{ align: 'center', justify: 'space-between' }}
                p={{ x: 3, y: 2 }}
                m={{ b: 2 }}>
                <Text subtitle primary={active_topic === topic}>
                  {topic}
                </Text>
              </StyledSelectableClickableArea>
            ))}
          </StyledResultsContainer>
        </Div>
      </StyledCardSection>
      <StyledCardSection p={3}>
        <Text muted size="s">
          The Example Webhooks dataset is open source.{' '}
          <Link
            icon="arrow_forward"
            small
            href="https://github.com/hookdeck/webhook-samples"
            target="_blank">
            Contribute
          </Link>
        </Text>
      </StyledCardSection>
    </StyledCard>
  );
};

export default SampleWebhooks;
