import { useContext } from 'react';

import KeyValueTable from '../../../dashboard/components/common/KeyValueTable';
import RequestBody from '../../../dashboard/components/common/Request/RequestBody';
import RequestPath from '../../../dashboard/components/common/Request/RequestPath';
import RequestQuery from '../../../dashboard/components/common/Request/RequestQuery';
import Badge from '../../../dashboard/components/common/base/Badge';
import Button from '../../../dashboard/components/common/base/Button';
import { StyledCard, StyledCardSection } from '../../../dashboard/components/common/base/Card';
import Divider from '../../../dashboard/components/common/base/Divider';
import Text from '../../../dashboard/components/common/base/Text';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import filteredHeaders from '../../../dashboard/utils/headers';
import {
  getRejectionCauseBreakdown,
  rejection_cause_labels,
} from '../../../dashboard/utils/rejection-causes';
import { ConsoleGlobalContext } from '../context/ConsoleGlobalContext';
import { ConsoleResourceContext } from '../context/ConsoleResourceContext';
import { ConsoleSidebarContext } from '../context/ConsoleSidebarContext';

const RequestTab: React.FC<{ setTab: (tab: 'request' | 'response') => void }> = ({ setTab }) => {
  const { HookdeckAPI } = useContext(ConsoleGlobalContext);
  const { sidebar_data, selected_entry } = useContext(ConsoleSidebarContext);
  const { source, webhook } = useContext(ConsoleResourceContext);

  return (
    <>
      {sidebar_data && !webhook && (
        <>
          <Div flex={{ justify: 'space-between', align: 'center' }} p={4}>
            <Div>
              <Text heading as="h3" m={{ b: 1, t: 0 }}>
                Add a Destination
              </Text>
              <Text muted>Send this request an HTTP URL or Localhost.</Text>
            </Div>
            <Div>
              <Button outline icon="add" onClick={() => setTab('response')}>
                Add
              </Button>
            </Div>
          </Div>
          <Divider />
        </>
      )}
      {sidebar_data?.entry?.data && (
        <>
          <Div p={4}>
            {sidebar_data &&
              sidebar_data.type === 'request' &&
              'rejection_cause' in sidebar_data.entry &&
              sidebar_data.entry.rejection_cause &&
              sidebar_data.entry.rejection_cause !== 'NO_CONNECTION' && (
                <Div m={{ b: 4 }}>
                  <Text muted m={{ b: 1 }}>
                    Error
                  </Text>
                  <StyledCard>
                    <StyledCardSection p={3}>
                      <Badge danger m={{ b: 3 }}>
                        {rejection_cause_labels[sidebar_data.entry.rejection_cause]}
                      </Badge>
                      <Text as="p" m={{ b: 0 }}>
                        {
                          getRejectionCauseBreakdown(sidebar_data.entry.rejection_cause)({
                            request: sidebar_data.entry,
                            source,
                          }).text
                        }
                      </Text>
                    </StyledCardSection>
                  </StyledCard>
                </Div>
              )}
            {sidebar_data.entry.data.headers &&
              sidebar_data.entry.data.headers['x-hookdeck-original-method'] && (
                <>
                  <Text muted m={{ b: 1 }}>
                    Method
                  </Text>
                  <StyledCard p={{ x: 3, y: 2 }} m={{ b: 4 }}>
                    <Text>{sidebar_data.entry.data.headers['x-hookdeck-original-method']}</Text>
                  </StyledCard>
                </>
              )}
            <KeyValueTable
              compact
              label="Headers"
              object={filteredHeaders(sidebar_data.entry?.data?.headers || {})}
              preference_key="events:headers"
            />
            {sidebar_data.entry.data.path && (
              <Div m={{ top: 4 }}>
                <Text muted m={{ b: 1 }}>
                  Path
                </Text>
                <RequestPath path={sidebar_data.entry.data.path} />
              </Div>
            )}
            {sidebar_data.entry.data.query && (
              <Div m={{ top: 4 }}>
                <RequestQuery
                  compact
                  query={sidebar_data.entry.data.query}
                  parsed_query={sidebar_data.entry.data.parsed_query}
                />
              </Div>
            )}
            {selected_entry && (
              <>
                <Div m={{ top: 4 }}>
                  <RequestBody
                    compact
                    label="Body"
                    body={sidebar_data.entry.data.body}
                    {...(['event', 'request'].includes(sidebar_data.type)
                      ? {
                          HookdeckAPI,
                          type: sidebar_data.type as 'event' | 'request',
                          id: selected_entry.id,
                        }
                      : {})}
                  />
                </Div>
                <Div m={{ top: 3 }}>
                  <Text monospace muted size="s">
                    {selected_entry.id}
                  </Text>
                </Div>
              </>
            )}
          </Div>
        </>
      )}
      <Div m={{ y: 20 }} />
    </>
  );
};

export default RequestTab;
