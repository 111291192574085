import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactResizeDetector from 'react-resize-detector';
import styled, { css } from 'styled-components';

import * as PopperJS from '@popperjs/core';

import Button from './base/Button';
import { StyledCard } from './base/Card';
import { IconName } from './base/Icon';
import { Div, StyledUtilsProps } from './helpers/StyledUtils';

export interface DropdownProps extends StyledUtilsProps {
  active?: boolean;
  disabled?: boolean;
  placement?: PopperJS.Placement;
  label?: string;
  block?: boolean;
  warp_with_card?: boolean;
  prevent_event_on_children?: boolean;
  parent_width?:
    | boolean
    | {
        min?: number;
        max?: number;
      };

  children:
    | React.ReactNode
    | ((toggle: (show: boolean) => void, update?: () => void) => React.ReactNode);

  // Button props
  primary?: boolean;
  outline?: boolean;
  danger?: boolean;
  minimal?: boolean;
  small?: boolean | { text: 's' | 'm' };
  icon?: IconName;
  badge?: {
    label: string;
  };

  fit_to_screen?: boolean;

  onFocus?: () => void;
  onToggle?(opened: boolean): void;
  renderToggle?(
    opened: boolean,
    toggle: (opened: boolean, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  ): JSX.Element;
}

const StyledDropdownWrapper = styled.div<{ block?: boolean }>`
  height: max-content;

  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}
`;

const StyledDropdownCardWrapper = styled.div<{
  width?: string;
  min_width?: string;
  max_width?: string;
}>`
  z-index: ${({ theme }) => theme.zindex.dropdown};

  ${({ min_width }) => css`
    min-width: ${min_width};
  `}

  ${({ max_width }) => css`
    max-width: ${max_width};
  `}

    ${({ max_width, min_width }) =>
    max_width &&
    min_width &&
    css`
      width: 100%;
    `}
`;

const StyledBackground = styled.div`
  z-index: ${({ theme }) => theme.zindex.dropdown - 1};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledDropdown = styled(Div)<DropdownProps>`
  > div {
    box-shadow: ${({ theme }) => theme.elevation[3]};
    width: 100%;
  }
`;

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    active,
    label,
    icon,
    prevent_event_on_children,
    parent_width,
    onFocus,
    onToggle,
    renderToggle,
    children,
    warp_with_card = true,
    fit_to_screen = true,
  } = props;
  const [opened, toggle] = useState(active ? true : false);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (opened && onFocus !== undefined) {
      onFocus();
    }
  }, [opened]);

  const placement: PopperJS.Placement = props.placement || 'bottom-start';

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: true,
          altAxis: true,
          padding: 8,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const handleToggle = (opened: boolean) => {
    if (active) {
      return;
    }
    if (onToggle) {
      onToggle(opened);
    }
    toggle(opened);
  };

  const handleToggleWithPreventDefault = (
    opened: boolean,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e && prevent_event_on_children) {
      e.preventDefault();
      if (e.target === e.currentTarget) handleToggle(opened);
      return;
    }
    handleToggle(opened);
  };

  const handleUpdate = () => {
    if (update !== null) {
      update();
    }
  };

  useEffect(() => {
    handleUpdate();
  }, [children]);

  return (
    <>
      <StyledDropdownWrapper block={!!props.block}>
        <ReactResizeDetector handleHeight onResize={handleUpdate}>
          {renderToggle ? (
            <div ref={setReferenceElement}>
              {renderToggle(opened, handleToggleWithPreventDefault)}
            </div>
          ) : (
            <div ref={setReferenceElement}>
              <Button
                m={props.m}
                outline={props.outline}
                primary={props.primary}
                danger={props.danger}
                minimal={props.minimal}
                badge={props.badge}
                dropdown
                block={!!props.block}
                small={props.small}
                icon={icon ? icon : undefined}
                onClick={() => handleToggle(!opened)}>
                {label}
              </Button>
            </div>
          )}
        </ReactResizeDetector>
        {opened && (
          <StyledDropdownCardWrapper
            min_width={
              parent_width
                ? typeof parent_width === 'object' && parent_width?.min
                  ? `${parent_width.min}px`
                  : `${referenceElement?.clientWidth}px`
                : undefined
            }
            max_width={
              parent_width
                ? typeof parent_width === 'object' && parent_width?.max
                  ? `${parent_width.max}px`
                  : `${referenceElement?.clientWidth}px`
                : undefined
            }
            ref={fit_to_screen ? setPopperElement : undefined}
            style={
              fit_to_screen
                ? styles.popper
                : { marginTop: '4px', zIndex: 1030, position: 'relative' }
            }
            {...(fit_to_screen ? attributes.popper : {})}>
            <StyledDropdown>
              {warp_with_card ? (
                <StyledCard
                  {...props}
                  style={{ maxHeight: '90vh', overflowY: 'auto' }}
                  overflow_hidden>
                  {typeof children === 'function'
                    ? children(handleToggleWithPreventDefault, update || undefined)
                    : children}
                </StyledCard>
              ) : typeof children === 'function' ? (
                children(handleToggleWithPreventDefault, update || undefined)
              ) : (
                children
              )}
            </StyledDropdown>
          </StyledDropdownCardWrapper>
        )}
      </StyledDropdownWrapper>
      {opened && <StyledBackground onClick={() => handleToggleWithPreventDefault(false)} />}
    </>
  );
};

export default Dropdown;
