import { useToasts } from '../components/common/Toast';

const fallbackCopyTextToClipboard = (text: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let success;
    try {
      success = document.execCommand('copy');
    } catch (err) {
      success = false;
    }
    resolve(success);
  });
};

export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  try {
    if (!navigator.clipboard) {
      return fallbackCopyTextToClipboard(text);
    }
    try {
      return new Promise((resolve, rejected) => {
        setTimeout(async () => {
          try {
            await navigator.clipboard.writeText(text);
            resolve(true);
          } catch (e) {
            rejected(e);
          }
        });
      });
    } catch (e) {
      return fallbackCopyTextToClipboard(text);
    }
  } catch (e) {
    return fallbackCopyTextToClipboard(text);
  }
};

export const useCopyToClipboard = (): ((text: string) => void) => {
  const { addToast } = useToasts();
  return async (text: string) =>
    await copyTextToClipboard(text).then(() => addToast('success', 'Copied to clipboard'));
};
