import { useState } from 'react';

const useLocalStorage = <T,>(
  key: string,
  initial_value: T,
  session_only = false,
): [T, (value: T | null | Function) => void] => {
  const storage = session_only ? window.sessionStorage : window.localStorage;

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = storage.getItem(key);
      return item !== null && item !== undefined ? JSON.parse(item) : initial_value;
    } catch (error) {
      console.log(key, error);
      return initial_value;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(key, error);
    }
  };
  return [storedValue, setValue];
};

export default useLocalStorage;
