import { EffectCallback, useEffect, useState } from 'react';

const useDidMount = (fn: EffectCallback) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return undefined;
  }, []);

  useEffect(() => {
    if (mounted) {
      return fn();
    }
    return undefined;
  }, [mounted]);
};

export default useDidMount;
