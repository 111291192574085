const editor_jsdocs = {
  transformation: `/**
* @typedef {Object} RequestInput
* @property headers {Object.<string, string>} - The original request headers.
* @property body {(string | number | string[] | number[] | boolean | null | Object.<*,*>)} - The original request body.
* @property query {string} - The original request URL query.
* @property parsed_query {Object.<string,*>} - The JSON representation of the query.
* @property path {string} - The original request URL pathname.
*/

/** @typedef {object} Connection
 * @property {string} id
 * @property {string} team_id
 * @property {null} archived_at
 * @property {string} updated_at
 * @property {string} created_at
 * @property {null} paused_at
 * @property {string} name
 * @property {} rules
 * @property {object} destination
 * @property {string} destination.id
 * @property {string} destination.team_id
 * @property {string} destination.url
 * @property {string | null} destination.archived_at
 * @property {string} destination.updated_at
 * @property {string} destination.created_at
 * @property {string | null} destination.rate_limit
 * @property {string} destination.rate_limit_period
 * @property {string | null} destination.cli_path
 * @property {boolean} destination.path_forwarding_disabled
 * @property {string} destination.name
 * @property {object} source
 * @property {string} source.id
 * @property {string} source.team_id
 * @property {string | null} source.archived_at
 * @property {string} source.updated_at
 * @property {string} source.created_at
 * @property {string} source.name
 * @property {string} source.url
 */

/**
* @typedef {Object} Context
* @property {Connection} connection - The current connection the transformation is running on.
*/

/**
* This callback is displayed as a global member.
* @callback RequestCallback
* @param {RequestInput} request
* @param {Context} context
* @returns {RequestInput}
*/

/**
* Register a tranformation handler
* @param {"transform"} type
* @param {RequestCallback} callback
*/
function addHandler(type, callback) {}`,
};

export default editor_jsdocs;
