import ThemeProvider from '@hookdeck/theme';
import { DialogProvider } from '../../dashboard/components/common/Dialog';
import { ToastProvider } from '../../dashboard/components/common/Toast';
import { GlobalContextProvider } from '../../dashboard/components/contexts/GlobalContext';
import ConsoleContent from './ConsoleContent';
import ErrorBoundary from './ErrorBoundary';
import Layout from './Layout';
import Nav from './Nav';
import { ConsoleEntriesContextProvider } from './context/ConsoleEntriesContext';
import { ConsoleGlobalContextProvider } from './context/ConsoleGlobalContext';
import { ConsoleResourceContextProvider } from './context/ConsoleResourceContext';
import { ConsoleSidebarContextProvider } from './context/ConsoleSidebarContext';

type ConsoleProvider = 'shopify';

export type ConsoleContext = 'fullpage' | 'embed' | 'inline';

const App: React.FC<{
  context: ConsoleContext;
  provider?: ConsoleProvider;
  telemetry: boolean;
  onClose?: () => void;
  LogRocket?: any;
}> = ({ context, provider, telemetry, onClose, LogRocket }) => {
  return (
    <ThemeProvider is_shadow_node={context !== 'fullpage'}>
      <ErrorBoundary LogRocket={LogRocket}>
        <GlobalContextProvider
          configs={{
            API_URL: VITE_API_URL,
            APP_URL: VITE_APP_URL,
            SENTRY_DSN: VITE_SENTRY_DSN,
            ROOT_DOMAIN: VITE_ROOT_DOMAIN,
            WEBSITE_URL: VITE_WEBSITE_URL,
            ENV: VITE_APP_ENV,
          }}>
          <ToastProvider>
            <DialogProvider>
              <Layout context={context}>
                <ConsoleGlobalContextProvider
                  telemetry={telemetry}
                  context={context}
                  provider={provider}
                  LogRocket={LogRocket}>
                  <ConsoleSidebarContextProvider>
                    <ConsoleResourceContextProvider>
                      <ConsoleEntriesContextProvider>
                        <Nav context={context} onClose={onClose} />
                        <ConsoleContent />
                      </ConsoleEntriesContextProvider>
                    </ConsoleResourceContextProvider>
                  </ConsoleSidebarContextProvider>
                </ConsoleGlobalContextProvider>
              </Layout>
            </DialogProvider>
          </ToastProvider>
        </GlobalContextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
