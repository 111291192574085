import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { generateUEID } from '../../utils';
import Button from './base/Button';
import Icon, { IconName } from './base/Icon';
import Text from './base/Text';
import { Div } from './helpers/StyledUtils';

type AppearanceType = 'error' | 'info' | 'success' | 'warning';

type Toast = {
  id: string;
  appearance: AppearanceType;
  message: string;
};

type Context = {
  add: (appearance: AppearanceType, message: string) => string;
  remove: (id: string) => void;
  removeAll: () => void;
};

const auto_dismiss_timeout = 4000;
const transition_timeout = 220;

const ToastContext = React.createContext<Context>({} as any);
const { Provider } = ToastContext;

const StyledToast = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    overflow: hidden;
    background-color: ${theme.colors.surface.base.surface};
    border-radius: ${theme.radius.normal};
    box-shadow: ${theme.elevation[3]};
    display: flex;
    align-items: center;
    transition:
      transform ${transition_timeout}ms cubic-bezier(0.2, 0, 0, 1),
      opacity ${transition_timeout}ms;
    width: 360px;
    border-radius: ${theme.radius.normal};
    border: ${theme.border};
  `,
);

const StyledToastContainer = styled.div<{ has_toasts: boolean }>(
  ({ theme, has_toasts }) => css`
    box-sizing: border-box;
    max-height: 100%;
    overflow: visible;
    padding: ${theme.spacing(6)};
    pointer-events: ${has_toasts ? null : 'none'};
    position: fixed;
    z-index: 2000;
    bottom: 0;
    left: 0;

    > div:not(:last-child) {
      margin-bottom: ${theme.spacing(4)};
    }
  `,
);

const toast_appearances: {
  [k: string]: { icon: IconName };
} = {
  success: {
    icon: 'success',
  },
  info: {
    icon: 'info',
  },
  error: {
    icon: 'error',
  },
  warning: {
    icon: 'warning',
  },
};

const StyledToastIcon = styled.div<{ appearance: AppearanceType }>(
  ({ theme, appearance }) => css`
    ${appearance === 'warning' &&
    css`
      color: ${theme.colors.on.hue.warning};
      background-color: ${theme.colors.surface.base.warning};
    `}

    ${appearance === 'error' &&
    css`
      color: ${theme.colors.on.hue.danger};
      background-color: ${theme.colors.surface.base.danger};
    `}

    ${appearance === 'info' &&
    css`
      color: ${theme.colors.on.hue.primary};
      background-color: ${theme.colors.surface.base.primary};
    `}

    ${appearance === 'success' &&
    css`
      color: ${theme.colors.on.hue.success};
      background-color: ${theme.colors.surface.base.success};
    `}

    border-radius: ${theme.radius.small};
    padding: ${theme.spacing(2)};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

function Timer(callback: () => void, delay: number) {
  let timerRef;
  let start,
    remaining = delay;
  this.clear = () => clearTimeout(timerRef);
  this.pause = () => {
    clearTimeout(timerRef);
    remaining -= Date.now() - start;
  };
  this.resume = () => {
    start = Date.now();
    clearTimeout(timerRef);
    timerRef = setTimeout(callback, remaining);
  };
  this.resume();
}

const toast_states = {
  entering: {},
  entered: { transform: 'translate3d(0,0,0)' },
  exiting: { transform: 'scale(0.8)', opacity: 0 },
  exited: { transform: 'scale(0.8)', opacity: 0 },
};

const Toast: React.FC<{
  appearance: AppearanceType;
  message: string;
  id: string;
  onDismiss: () => void;
  transition_state: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}> = ({ appearance, message, onDismiss, transition_state, onMouseEnter, onMouseLeave }) => {
  const [height, setHeight] = useState<string | number>('auto');
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (transition_state === 'entered') {
      const el = elementRef.current;
      if (el) setHeight(el.offsetHeight);
    }
    if (transition_state === 'exiting') {
      setHeight(0);
    }
  }, [transition_state]);
  return (
    <div
      ref={elementRef}
      style={{ height, transition: `height ${transition_timeout - 100}ms 100ms` }}>
      <StyledToast
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={toast_states[transition_state]}>
        <StyledToastIcon appearance={appearance}>
          <Icon icon={toast_appearances[appearance].icon} />
        </StyledToastIcon>
        <Div flex={{ justify: 'space-between', align: 'center' }} p={{ l: 3 }} w={100}>
          <Text subtitle>{message}</Text>
          <Button minimal m={{ l: 'auto' }} onClick={onDismiss} icon={'close'} />
        </Div>
      </StyledToast>
    </div>
  );
};

const ToastController: React.FC<{
  toast: Toast;
  onDismiss: () => void;
  transition_state: string;
}> = (props) => {
  const timer = useRef(new Timer(props.onDismiss, auto_dismiss_timeout));
  useEffect(() => () => timer.current.clear(), [timer]);
  const onMouseEnter = () => timer.current.pause();
  const onMouseLeave = () => timer.current.resume();

  return (
    <Toast
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      appearance={props.toast.appearance}
      message={props.toast.message}
      id={props.toast.id}
      {...props}
    />
  );
};

export const ToastProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const add = useCallback((appearance: AppearanceType, message: string) => {
    const id = generateUEID();
    setToasts((prev_toasts) => [...prev_toasts, { appearance, message, id }]);
    return id;
  }, []);

  const remove = useCallback(
    (id: string) => setToasts((prev_toasts) => prev_toasts.filter((t) => t.id !== id)),
    [],
  );
  const removeAll = useCallback(() => setToasts([]), []);

  const context = useMemo(() => ({ add, remove, removeAll }), []);

  const has_toasts = Boolean(toasts.length);

  return (
    <Provider value={context}>
      {children}
      {has_toasts && (
        <StyledToastContainer has_toasts={has_toasts}>
          <TransitionGroup component={null}>
            {toasts.map((toast) => (
              <Transition
                appear
                key={toast.id}
                mountOnEnter
                timeout={transition_timeout}
                unmountOnExit>
                {(transition_state) => (
                  <ToastController
                    key={toast.id}
                    toast={toast}
                    transition_state={transition_state}
                    onDismiss={() => remove(toast.id)}
                  />
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </StyledToastContainer>
      )}
    </Provider>
  );
};

export const useToasts = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToasts must be within a used with a ToastProvider');
  }

  return {
    addToast: context.add,
    removeToast: context.remove,
    removeAllToasts: context.removeAll,
  };
};
