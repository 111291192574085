import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { CliClient } from '../../../../../typings/CliClient.interface';

import { Team } from '../../../../../typings/Team.interface';
import { User } from '../../../../../typings/User.interface';
import HookdeckAPI from '../../../dashboard/client/Hookdeck';
import Icon from '../../../dashboard/components/common/base/Icon';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import { GlobalContext } from '../../../dashboard/components/contexts/GlobalContext';
import { ConsoleContext } from '../App';

interface ConsoleGlobalContextProps {
  telemetry: boolean;
  context: ConsoleContext;
  provider?: string;
  user: User;
  team: Team;
  cli_key: CliClient;
  HookdeckAPI: HookdeckAPI;
  logout: () => void;
}

export const ConsoleGlobalContext = createContext<ConsoleGlobalContextProps>({
  telemetry: true,
  context: {} as ConsoleContext,
  provider: 'shopify',
  user: {} as User,
  team: {} as Team,
  cli_key: {} as CliClient,
  HookdeckAPI: {} as HookdeckAPI,
  logout: () => null,
});

export const ConsoleGlobalContextProvider: React.FC<
  PropsWithChildren<{
    telemetry: boolean;
    context: ConsoleContext;
    provider?: string;
    LogRocket?: any;
  }>
> = ({ children, LogRocket, ...rest }) => {
  const { setAPITeamId, HookdeckAPI } = useContext(GlobalContext);

  const {
    data: response,
    mutate, // TODO: Handle error
  } = useSWR('getOrCreateConsoleGuest', () => HookdeckAPI.console.getUserOrCreateGuest(), {
    shouldRetryOnError: false,
  });

  useEffect(() => {
    if (response?.team && team) {
      setAPITeamId(team.id);
    }
  }, [response]);

  const { user, team, cli_key } = response || {};

  useEffect(() => {
    if (user && !user.is_guest) {
      if (VITE_APP_ENV === 'production' && LogRocket)
        LogRocket.identify(user.id, {
          name: user.name,
          email: user.email,
          createdAt: user.created_at,
          env: VITE_APP_ENV,
        });
    }
  }, [user]);

  const context: ConsoleGlobalContextProps = useMemo(
    () => ({
      user: user || ({} as User),
      team: team || ({} as Team),
      cli_key: cli_key || ({} as CliClient),
      HookdeckAPI: HookdeckAPI || ({} as HookdeckAPI),
      logout: () => {
        HookdeckAPI.session.logout().then((response) => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          setAPITeamId(null);
          mutate();
          if (response?.redirect_url) {
            window.location.replace(response?.redirect_url);
          }
        });
      },
      ...rest,
    }),
    [team, user, rest, cli_key, HookdeckAPI],
  );

  if (!context.user || !context.team || !context.cli_key || !context.HookdeckAPI?.team_id) {
    return (
      <Div h={100} flex={{ justify: 'center', align: 'center' }}>
        <Icon icon="loading" muted />
      </Div>
    );
  }

  return <ConsoleGlobalContext.Provider value={context}>{children}</ConsoleGlobalContext.Provider>;
};
