const isEmpty = (
  value: any,
  options?: {
    allow_null?: boolean;
    allow_empty_string?: boolean;
    allow_empty_object?: boolean;
    allow_empty_array?: boolean;
  },
) => {
  const {
    allow_null = false,
    allow_empty_string = false,
    allow_empty_object = false,
    allow_empty_array = false,
  } = options || {};

  if (value === undefined || (value === null && !allow_null)) {
    return true;
  }
  if (typeof value === 'string' && !allow_empty_string) {
    return value.length === 0;
  }
  if (typeof value === 'object' && !allow_empty_object) {
    return Object.keys(value).length === 0;
  }
  if (Array.isArray(value) && !allow_empty_array) {
    return value.length === 0;
  }

  return false;
};

export default isEmpty;
