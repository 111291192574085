import { useField } from 'formik';
import { ReactNode } from 'react';
import { IconName } from '../base/Icon';
import Tabs from '../base/Tabs';

interface Tab {
  fields: ReactNode;
  label: string;
  icon?: IconName;
  key: string;
}

const TabsFormSection: React.FC<{ name: string; tabs: Tab[]; default_tab?: string }> = ({
  name,
  tabs,
}) => {
  const [{ value }, , { setValue }] = useField<string>(name);
  return (
    <>
      <Tabs
        tabs={tabs}
        active_tab={value}
        p={{ t: 2, x: 2 }}
        m={{ b: 4 }}
        onTabSelected={(key) => {
          setValue(key);
        }}
      />
      {tabs.find((t) => t.key === value)?.fields || <p>No tab selected</p>}
    </>
  );
};

export default TabsFormSection;
