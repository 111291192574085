import styled, { css } from 'styled-components';

import { Div } from '../helpers/StyledUtils';

interface Props {
  xsmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;

  center?: boolean;
}

const Container = styled(Div)<Props>(
  ({ theme, xsmall, small, medium, large, center }) => css`
    margin: 0 auto;
    width: 100%;
    padding: 0 ${theme.spacing(8)};
    box-sizing: border-box;

    ${center
      ? css`
          text-align: center;
        `
      : ''}

    max-width: ${theme.pxToRem(
      ((xsmall && theme.breakpoints[0]) ||
        (small && theme.breakpoints[1]) ||
        (medium && theme.breakpoints[2]) ||
        (large && theme.breakpoints[3]) ||
        theme.breakpoints[4]) +
        8 * theme.grid_size * 2,
    )};
  `,
);

export default Container;
