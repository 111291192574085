import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { TeamRole } from '../../../../../typings/TeamMember.interface';
import { DashboardContext } from '../scenes/DashboardScene/DashboardContext';
import { team_levels_by_role } from '../../../../../domain/roles';

type Props = {
  role: TeamRole;
  children: React.ReactNode;
};

export type TeamPermissionContext = {
  role: TeamRole;
  authorized: boolean;
  shouldCurrentRoleAuthorized: (role: TeamRole) => boolean;
};

const TeamPermissionContext = createContext<TeamPermissionContext>({
  role: 'viewer',
  authorized: true, // default to true so components still work when they're used outside of <TeamPermission />
  shouldCurrentRoleAuthorized: () => true,
});

export const TeamPermission: React.FC<PropsWithChildren<Props>> = ({ role, children }) => {
  const { team_role: current_role } = useContext(DashboardContext);

  const shouldCurrentRoleAuthorized = useCallback(
    (allowed_role: TeamRole) =>
      current_role ? team_levels_by_role[current_role] >= team_levels_by_role[allowed_role] : false,
    [current_role],
  );

  const authorized = shouldCurrentRoleAuthorized(role);
  const context = useMemo(
    () => ({ role, authorized, shouldCurrentRoleAuthorized }),
    [role, authorized, shouldCurrentRoleAuthorized],
  );

  return (
    <TeamPermissionContext.Provider value={context}>{children}</TeamPermissionContext.Provider>
  );
};

export const withTeamPermission = (Component: any, role: TeamRole) => (props: any) => {
  return (
    <TeamPermission role={role}>
      <Component {...props} />
    </TeamPermission>
  );
};

export const useTeamPermission = () => useContext(TeamPermissionContext);

/**
 * Helper hook that accepts an optional 'allowed_role' parameter.
 * If not passed, use the nearest allowed role from component tree.
 */
export const useAuthorized = (allowed_role?: TeamRole) => {
  const { authorized, shouldCurrentRoleAuthorized } = useContext(TeamPermissionContext);
  if (typeof allowed_role === 'undefined') return authorized;
  return shouldCurrentRoleAuthorized(allowed_role);
};

/**
 *
 * @param disabled default disabled
 * @param role allowed role
 * @returns boolean
 */
export const usePermissionAwareDisabled = (disabled?: boolean, role?: TeamRole) => {
  return Boolean(!useAuthorized(role) || disabled);
};
