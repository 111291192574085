import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../helpers/StyledUtils';
import Button from './Button';
import Icon, { IconName } from './Icon';
import Text from './Text';

const icons_for_type = {
  warning: 'warning',
  success: 'success',
  danger: 'error',
  info: 'info',
  neutral: 'info',
  bare: 'info',
};

interface Props extends StyledUtilsProps {
  inline?: boolean;

  warning?: boolean;
  success?: boolean;
  danger?: boolean;
  info?: boolean;
  neutral?: boolean;
  bare?: boolean;

  buttonPrimary?: boolean;

  action?: {
    label: string;
    onClick?: () => void;
    to?: string;
    icon?: IconName;
  };
}

const StyledAlert = styled(Div)<Props>(
  ({ theme, ...props }) => css`
    font-weight: ${theme.font_weigths.medium};
    box-sizing: border-box;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    text-align: left;
    ${!props.inline
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : css`
          display: flex;
          align-items: flex-start;
          padding: ${theme.spacing(2)};
          border-radius: ${theme.radius.normal};
        `}

    ${theme.optionsToCss(
      {
        neutral: css`
          background-color: ${theme.colors.surface.container.neutral};
          border: 1px solid ${theme.colors.outline.neutral};
        `,
        bare: css`
          background-color: transparent;
          border: 1px solid ${theme.colors.outline.neutral};
        `,
        info: css`
          background-color: ${theme.colors.surface.container.primary};
          border: 1px solid ${theme.colors.outline.primary};
        `,
        success: css`
          background-color: ${theme.colors.surface.container.success};
          border: 1px solid ${theme.colors.outline.success};
        `,
        warning: css`
          background-color: ${theme.colors.surface.container.warning};
          border: 1px solid ${theme.colors.outline.warning};
        `,
        danger: css`
          background-color: ${theme.colors.surface.container.danger};
          border: 1px solid ${theme.colors.outline.danger};
        `,
      },
      props,
      'neutral',
    )}
  `,
);

const Alert: React.FC<PropsWithChildren<Props>> = ({ children, inline, action, ...props }) => (
  <StyledAlert inline={inline} {...props}>
    <Icon
      p={1.5}
      icon={icons_for_type[Object.keys(icons_for_type).find((key) => !!props[key]) || 'neutral']}
      warning={!!props.warning}
      success={!!props.success}
      danger={!!props.danger}
      primary={!!props.info}
      left
    />
    <Text as="p" m={{ y: 1 }}>
      {children}
    </Text>
    {action && (
      <Div m={{ l: 'auto' }} p={{ l: 8 }}>
        <Button
          primary={!!props.info || !!props.buttonPrimary}
          small
          outline
          onClick={action.onClick}
          to={action.to}
          icon={action.icon}>
          {action.label}
        </Button>
      </Div>
    )}
  </StyledAlert>
);

export default Alert;
