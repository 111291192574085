import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Div } from '../helpers/StyledUtils';
import Icon from './Icon';
import Text from './Text';

const Loading: React.FC<{
  loading_title?: string;
  loading_message?: string;

  require?: any[];

  small?: boolean;
  large?: boolean;

  wrapper?: (node: React.ReactNode) => React.ReactElement;
  children?: () => React.ReactElement;
}> = ({ loading_title, loading_message, require, small, large, wrapper, children }) => {
  const [past_timeout, setPastTimeout] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setPastTimeout(true), 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const loading = (
    <Div flex={{ justify: 'center', direction: 'column', align: 'center' }}>
      <Icon icon="loading" small={small} large={large} muted />
      {loading_title && (
        <Text size="l" heading m={{ t: 1 }}>
          {loading_title}
        </Text>
      )}
      {loading_message && <Text>{loading_message}</Text>}
    </Div>
  );

  if (!require || require.some((p) => p === undefined)) {
    if (!past_timeout) {
      return <div style={{ opacity: 0 }}>{loading}</div>;
    }
    if (wrapper) {
      return wrapper(loading);
    }
    return loading;
  }
  return children ? children() : null;
};

const StyledLoadingBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.32);
  width: 100w;
  height: 100vh;
  z-index: ${({ theme }) => theme.zindex.modal_backdrop};
`;

export const OverlayLoading = () => {
  return (
    <StyledLoadingBackdrop>
      <Loading />
    </StyledLoadingBackdrop>
  );
};

export default Loading;
