import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  environment: VITE_APP_ENV,
  release: VITE_BUILD_ID,
  integrations: [
    new Sentry.Integrations.GlobalHandlers({ onerror: false, onunhandledrejection: false }),
  ],
  beforeSend: (event) => {
    if (VITE_APP_ENV === 'development') {
      return null;
    }
    return event;
  },
});

export default { captureException: Sentry.captureException, withScope: Sentry.withScope };
