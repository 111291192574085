import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import Button from './base/Button';
import Icon, { IconName, IconTheme } from './base/Icon';
import Text from './base/Text';
import { Div } from './helpers/StyledUtils';

const widths = {
  small: '420px',
  medium: '560px',
  large: '720px',
  full: '100%',
};

interface Props {
  title?: string;
  title_icon?: IconName;
  icon_theme?: IconTheme;
  subtitle?: string;
  portal?: boolean;
  cancel_label?: string;
  submit_label?: string;
  submit_icon?: IconName;
  size?: keyof typeof widths;
  cancel?: string;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  is_submitting?: boolean;
  submit_disabled?: boolean;
  danger?: boolean;
}

export const StyledModalBackdrop = styled.div<{ size: keyof typeof widths }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.surface.base.backdrop};
  backdrop-filter: blur(3px);
  z-index: ${({ theme }) => theme.zindex.modal_backdrop};

  ${({ size, theme }) =>
    size === 'full'
      ? css`
          ${StyledModalSection}:first-of-type {
            flex-grow: 1;
          }
          ${StyledModalSection}:first-of-type, ${StyledModalSection}:last-of-type {
            margin: 0 !important;

            border-radius: 0 !important;
          }
        `
      : css`
          padding: 0 ${theme.spacing(6)};
        `}
`;

export const StyledModalScreen = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${theme.colors.surface.base.surface};
    z-index: ${theme.zindex.screen_modal};

    form {
      height: 100%;
    }
  `,
);

const StyledModalSection = styled(Div)`
  background-color: ${({ theme }) => theme.colors.surface.base.surface};
  border-bottom: ${({ theme }) => theme.border};
`;

const StyledModalCard = styled.div<{ size: keyof typeof widths }>`
  width: 100%;
  max-width: ${({ size }) => widths[size] || widths.medium};
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  height: ${({ size }) => (size === 'full' ? '100vh' : 'fit-content')};
  max-height: 90vh;
  z-index: ${({ theme }) => theme.zindex.modal};
  box-shadow: ${({ theme }) => theme.elevation[3]};
  border-radius: ${({ theme }) => theme.radius.large};

  ${StyledModalSection} {
    &:first-of-type {
      border-top: ${({ theme }) => theme.border};
      border-left: ${({ theme }) => theme.border};
      border-right: ${({ theme }) => theme.border};
      border-top-left-radius: ${({ theme }) => theme.radius.large};
      border-top-right-radius: ${({ theme }) => theme.radius.large};
    }
    &:nth-of-type(2) {
      border-left: ${({ theme }) => theme.border};
      border-right: ${({ theme }) => theme.border};
      ::-webkit-scrollbar {
        display: none;
      }
    }
    &:last-of-type {
      border-left: ${({ theme }) => theme.border};
      border-right: ${({ theme }) => theme.border};
      border-bottom: ${({ theme }) => theme.border};
      border-bottom-left-radius: ${({ theme }) => theme.radius.large};
      border-bottom-right-radius: ${({ theme }) => theme.radius.large};
    }
  }
`;

const ModalContainer: React.FC<PropsWithChildren<{ portal?: boolean }>> = ({
  children,
  portal,
}) => {
  const element = useMemo(() => {
    if (!document.getElementById('app-modal')) {
      const div = document.createElement('div');
      div.id = 'app-modal';
      document.body.appendChild(div);
    }
    return document.getElementById('app-modal') as HTMLElement;
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  if (portal) {
    return createPortal(children, element);
  }
  return <>{children}</>;
};

export const ScreenModal: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <ModalContainer portal>
      <StyledModalScreen>{children}</StyledModalScreen>
    </ModalContainer>
  );
};

const Modal: React.FC<PropsWithChildren<Props>> = ({
  title,
  title_icon,
  icon_theme,
  subtitle,
  portal,
  cancel_label,
  submit_label,
  submit_icon,
  children,
  size = 'medium',
  danger = false,
  submit_disabled = false,
  onSubmit,
  onClose,
  onCancel,
  is_submitting,
}) => {
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation();
      if (
        event.button === 0 &&
        modalRef.current &&
        !event.composedPath().includes(modalRef.current)
      ) {
        onClose();
      }
    };
    if (size !== 'full') {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (size !== 'full') {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [onClose, size]);

  const primary_or_danger = danger ? { danger: true } : { primary: true };

  return (
    <ModalContainer portal={!!portal}>
      <StyledModalBackdrop size={size}>
        <StyledModalCard id="modal" ref={modalRef} size={size}>
          {title && (
            <StyledModalSection p={{ x: 4, y: 3 }}>
              <Div flex={{ justify: 'space-between', align: 'center' }}>
                <Text heading size="l">
                  {title}
                  {title_icon && <Icon icon={title_icon} right {...icon_theme} />}
                </Text>
                {onClose && <Button outline onClick={onClose} icon="close" />}
              </Div>
              {subtitle && (
                <Text muted m={{ b: 2 }}>
                  {subtitle}
                </Text>
              )}
            </StyledModalSection>
          )}
          <StyledModalSection style={{ overflow: 'auto' }} p={4}>
            {children}
          </StyledModalSection>
          {(onCancel || submit_label) && (
            <StyledModalSection flex={{ justify: 'flex-end' }} p={{ x: 4, y: 3 }}>
              {!is_submitting && onCancel && cancel_label && (
                <Button disabled={is_submitting} outline onClick={onCancel}>
                  {cancel_label}
                </Button>
              )}
              {submit_label && (
                <Button.Permission
                  disabled={is_submitting || submit_disabled}
                  {...primary_or_danger}
                  submit
                  onClick={onSubmit || undefined}
                  icon={is_submitting ? 'loading' : submit_icon || undefined}
                  m={{ l: 2 }}>
                  {submit_label}
                </Button.Permission>
              )}
            </StyledModalSection>
          )}
        </StyledModalCard>
      </StyledModalBackdrop>
    </ModalContainer>
  );
};

export default Modal;
