import styled from 'styled-components';

export const Grid = styled.div<{ gap?: number }>`
  display: flex;
  width: 100%;
  gap: ${({ theme, gap }) => theme.spacing(gap || 0)};
`;

export const GridUnit = styled.div<{ size: number }>`
  flex-basis: ${({ size }) => `${Math.round(size * 100)}%`};
  max-width: ${({ size }) => `${Math.round(size * 100)}%`};
`;
