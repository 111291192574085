import { useEffect, useState } from 'react';

import { ThemeModes } from './themes';

export const useThemeMode = (): [ThemeModes, (theme: ThemeModes) => void] => {
  const initial_theme = () => {
    const default_theme = 'system';
    if (typeof window === 'undefined') return default_theme;

    return (window.localStorage.getItem('theme') as ThemeModes) || default_theme;
  };
  const [theme, setTheme] = useState<ThemeModes>(initial_theme);
  const changeTheme = (theme: ThemeModes) => {
    window.localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  useEffect(() => {
    window.document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return [theme, changeTheme];
};
