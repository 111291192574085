import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { Event } from '../../../../../typings/Event.interface';

import { EventAttempt } from '../../../../../typings/EventAttempt.interface';
import APIMethodKeys from '../../../dashboard/client/APIMethodKeys';
import Badge from '../../../dashboard/components/common/base/Badge';
import Button from '../../../dashboard/components/common/base/Button';
import Text from '../../../dashboard/components/common/base/Text';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import RequestPayload from '../../../dashboard/components/common/Request/RequestBody';
import { getErrorCodeLabels } from '../../../dashboard/utils/error-codes';
import { ConsoleEntriesContext } from '../context/ConsoleEntriesContext';
import { ConsoleGlobalContext } from '../context/ConsoleGlobalContext';
import { Status } from '../../../dashboard/components/common/Status';

type EventsAttemptPreviewProps = {
  event_id: string;
  attempt_id?: string;
};

const EventAttemptPreview: React.FC<EventsAttemptPreviewProps> = ({ event_id, attempt_id }) => {
  const { HookdeckAPI } = useContext(ConsoleGlobalContext);
  const { entries, retryEvent } = useContext(ConsoleEntriesContext);

  const { data: attempts, mutate: mutateAttempts } = useSWR(
    !attempt_id && APIMethodKeys.attempts.list({ event_id, limit: 1 }),
    () => HookdeckAPI.attempts.list({ event_id, limit: 1 }),
  );

  const resolved_attempt_id = attempt_id || attempts?.models[0]?.id || '';

  const { data: attempt, mutate: mutateAttempt } = useSWR(
    resolved_attempt_id && APIMethodKeys.attempts.get(resolved_attempt_id),
    () => HookdeckAPI.attempts.get(resolved_attempt_id),
  );

  const event = entries?.find((entry) => entry.id === event_id) as Event;
  useEffect(() => {
    if (attempt && event) {
      if (event.attempts !== attempt.attempt_number) {
        mutateAttempts();
      }
      if (event.response_status !== attempt.response_status) {
        mutateAttempt();
      }
    }
  }, [event]);

  if (!attempt_id && !attempt) {
    return null;
  }

  return (
    <Div p={4}>
      <Text muted m={{ b: 2 }}>
        Status
      </Text>
      <Div flex={{ justify: 'space-between' }}>
        {attempt && <Status {...attempt} />}
        <Button disabled={!attempt} small outline icon="retry" onClick={() => retryEvent(event_id)}>
          Retry
        </Button>
      </Div>
      <Div m={{ top: 2 }}>
        {attempt && <EventAttemptPreviewContent attempt={attempt} />}
        <Div m={{ top: 3 }}>
          <Text monospace size="s" muted>
            {attempt ? attempt.id : attempt_id}
          </Text>
        </Div>
      </Div>
    </Div>
  );
};

const EventAttemptPreviewContent = ({ attempt }: { attempt: EventAttempt }) => {
  if (!attempt) {
    return <Text as="p">No attempts has been made yet, it will be made shortly.</Text>;
  }

  if (!attempt.error_code && !attempt.response_status) {
    return null;
  }

  if (attempt.error_code) {
    const label = getErrorCodeLabels()[attempt.error_code];
    return (
      <>
        <Text m={{ top: 4 }} as="p">
          {label}
        </Text>
        <Badge danger>{attempt.error_code}</Badge>
      </>
    );
  }

  return (
    <>
      {attempt.requested_url && (
        <>
          <Text muted m={{ top: 4, b: 2 }}>
            Requested URL
          </Text>
          <Text overflow_wrap="anywhere">
            {attempt.http_method && (
              <Text as="span" subtitle m={{ r: 1 }}>
                {attempt.http_method.toUpperCase()}
              </Text>
            )}
            {attempt.requested_url}
          </Text>
        </>
      )}
      <Div m={{ top: 4 }}>
        <RequestPayload label="Body" compact body={attempt.body || {}} />
      </Div>
    </>
  );
};

export default EventAttemptPreview;
