import slugify from 'slugify';

const field_formats = {
  slugify: (v: string): string => {
    v = v.replace(/ /g, '-');
    v = v.replace(/_{2,}/g, '_');
    v = v.replace(/_-/g, '_');
    v = v.replace(/-_/g, '-');

    return slugify(v, {
      replacement: '-',
      lower: false,
      remove: /[^\w\s-]+/g,
      trim: false,
    });
  },
  viewName: (v: string): string => {
    return v.replace(/[^\w\s\-_()]+/g, '');
  },
  slugifyWithWildcard: (v: string): string => {
    if (v === '*') return '*';
    if (v === '**') return '*';
    if (v === '') return v;
    v = v.replace(/ /g, '-');
    let slug = slugify(v, { lower: true, strict: true, trim: false });
    if (v[0] === '*') {
      slug = `*${slug}`;
    }
    if (v[v.length - 1] === '*') {
      slug = `${slug}*`;
    }
    return slug;
  },
};

export default field_formats;
