import { copyTextToClipboard } from '../../../utils/copy';
import useLocalStorage from '../../hooks/useLocalStorage';
import Button from '../base/Button';
import { StyledCard, StyledCardSection } from '../base/Card';
import Text from '../base/Text';
import DropdownMenu from '../DropdownMenu';
import { Div } from '../helpers/StyledUtils';
import JSONBrowser from '../JSONBrowser';
import { useToasts } from '../Toast';

type Props = {
  parsed_query: object;
  query: string;
  compact?: boolean;
};

const format_label = {
  json: 'Structured',
  raw: 'Raw',
};

const RequestQuery: React.FC<Props> = ({ query, parsed_query, compact }) => {
  const [query_format, setQueryFormat] = useLocalStorage<'raw' | 'json'>(
    'pref:query_format',
    'json',
  );
  const { addToast } = useToasts();

  return (
    <StyledCard>
      <StyledCardSection flex={{ align: 'center', justify: 'space-between' }} p={{ x: 4, y: 2 }}>
        <Text subtitle size={compact ? 's' : 'm'}>
          Query
        </Text>
        {query && (
          <Div flex={{ gap: 1 }}>
            <DropdownMenu
              label={format_label[query_format]}
              placement="bottom-end"
              icon={query_format === 'json' ? 'data_object' : 'notes'}
              minimal
              small={
                compact
                  ? {
                      text: 's',
                    }
                  : true
              }
              options={[
                {
                  label: 'Structured',
                  icon: 'data_object',
                  onClick: () => setQueryFormat('json'),
                },
                { label: 'Raw', icon: 'notes', onClick: () => setQueryFormat('raw') },
              ]}
            />
            <Button
              onClick={() =>
                copyTextToClipboard(
                  query_format === 'raw' ? `?${query}` : JSON.stringify(parsed_query, null, 2),
                ).then((success) =>
                  success
                    ? addToast('success', 'Copied to clipboard')
                    : addToast('error', 'Failed to copy'),
                )
              }
              icon="copy"
              minimal
              small
            />
          </Div>
        )}
      </StyledCardSection>
      <StyledCardSection muted p={{ x: 4, y: 3 }}>
        {query ? (
          <>
            {query_format === 'json' && parsed_query ? (
              <JSONBrowser compact={!!compact} json={parsed_query as any} />
            ) : (
              <Text monospace size={compact ? 's' : 'm'} word_break="break-all" as="span">
                ?{query}
              </Text>
            )}
          </>
        ) : (
          <Text muted size={compact ? 's' : 'm'}>
            Empty
          </Text>
        )}
      </StyledCardSection>
    </StyledCard>
  );
};

export default RequestQuery;
