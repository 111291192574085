import { createContext, Dispatch, PropsWithChildren, useMemo, useState } from 'react';

import HookdeckAPI from '../../client/Hookdeck';

interface Configs {
  API_URL: string;
  APP_URL: string;
  SENTRY_DSN: string;
  ROOT_DOMAIN: string;
  WEBSITE_URL: string;
  ENV: string;
}

type Props = {
  children: React.ReactNode;
  configs: Configs;
};

export type GlobalContext = {
  HookdeckAPI: HookdeckAPI;
  setAPITeamId: Dispatch<string | null>;
  configs: Configs;
};

export const GlobalContext = createContext<GlobalContext>({
  HookdeckAPI: {} as HookdeckAPI,
  setAPITeamId: () => null,
  configs: {} as Configs,
});

export const GlobalContextProvider: React.FC<PropsWithChildren<Props>> = ({
  configs,
  children,
}) => {
  const [api_team_id, setAPITeamId] = useState<string | null>(null);
  const hookdeckAPI = useMemo(
    () => new HookdeckAPI(configs.API_URL, api_team_id || ''),
    [configs.API_URL, api_team_id],
  );

  const context = useMemo(
    () => ({
      HookdeckAPI: hookdeckAPI,
      setAPITeamId,
      configs,
    }),
    [hookdeckAPI, setAPITeamId, configs],
  );

  return <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>;
};
